import React from 'react'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import transfersActions from 'actions/revolucharge/transfers'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import Chip from '@material-ui/core/Chip'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'

const initialRowsPerPage = 15

class Transfers extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
  }

  componentDidMount () {
    this.dispatch(transfersActions.fetch({ limit: initialRowsPerPage }))
  }

  render () {
    return (
      <NamespacesConsumer ns={['transfers']}>
        {t => {
          const columns = [
            {
              name: t('ID')
            },
            {
              name: t('PROVIDER')
            },
            {
              name: t('COUNTRY')
            },
            {
              name: t('OPERATOR')
            },
            {
              name: t('BENEFICIARY')
            },
            {
              name: t('SEND'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('RECEIVE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('STATUS'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  let color = 'primary'
                  if (value === 'SUCCESS') {
                    color = 'secondary'
                  }
                  if (value === 'PENDING') {
                    color = 'default'
                  }
                  return (
                    <Chip label={t(value)} color={color} />
                  )
                }
              }
            },
            {
              name: t('APPROVED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Chip label={value ? t('YES') : t('NO')} color={value ? 'secondary' : 'primary'} />
                )
              }
            },
            {
              name: t('CREATED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateTimeFormat value={new Date(value)} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value) {
                    return (<IconButton onClick={() => {
                      this.history.push(`/revolucharge/order/${value}`)
                    }}>
                      <LaunchIcon />
                    </IconButton>
                    )
                  } else {
                    return ('Batch')
                  }
                }
              }
            }
          ]
          let data = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.transfers.data.transfers) {
            count = this.props.transfers.data.count
            page = this.props.transfers.data.page
            rowsPerPage = this.props.transfers.data.limit
            data = this.props.transfers.data.transfers.map(t => ([
              t.transfer.id,
              t.provider.name,
              t.country.name,
              t.operator.name,
              `+${t.country.prefix}${t.transfer.beneficiary}`,
              { amount: t.transfer.sendValue, currency: t.product.sendCurrencyIso },
              { amount: t.transfer.receiveValue, currency: t.product.receiveCurrencyIso },
              t.transfer.status,
              t.transfer.approved,
              t.transfer.createdAt,
              t.order ? t.order.id : null
            ]))
          }
          const options = {
            serverSide: true,
            count,
            page,
            responsive: 'scroll',
            filter: false,
            download: false,
            selectableRows: false,
            sort: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.searchterm = searchText
                }
                this.dispatch(transfersActions.fetch(params))
              }
            }
          }
          return (
            <main>
              {this.props.transfers.loading && !this.props.transfers.data.transfers &&
                <Loading />
              }
              {this.props.transfers.data.transfers &&
                <MUIDataTable data={data} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ transfers: state.transfers }))(withRouter(Transfers))
