import React from 'react'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import productsActions from 'actions/revolucharge/products'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'

const initialRowsPerPage = 15

class Products extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
  }

  componentDidMount () {
    this.dispatch(productsActions.fetch({ limit: initialRowsPerPage }))
  }

  render () {
    return (
      <NamespacesConsumer ns={['products']}>
        {t => {
          const columns = [
            {
              name: t('PROVIDER')
            },
            {
              name: t('COUNTRY')
            },
            {
              name: t('OPERATOR')
            },
            {
              name: t('REFERENCE')
            },
            {
              name: t('NAME')
            },
            {
              name: t('MIN_SEND_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MAX_SEND_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MIN_RECEIVE_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MAX_RECEIVE_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('CREATED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateTimeFormat value={new Date(value)} />
                )
              }
            }
          ]
          let data = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.products.data.products) {
            count = this.props.products.data.count
            page = this.props.products.data.page
            rowsPerPage = this.props.products.data.limit
            data = this.props.products.data.products.map(t => ([
              t.provider.name,
              t.country.name,
              t.operator.name,
              t.product.reference,
              t.product.name,
              { amount: t.product.minSendValue, currency: t.product.sendCurrency },
              { amount: t.product.maxSendValue, currency: t.product.sendCurrency },
              { amount: t.product.minReceiveValue, currency: t.product.receiveCurrency },
              { amount: t.product.maxReceiveValue, currency: t.product.receiveCurrency },
              t.product.createdAt
            ]))
          }
          const options = {
            serverSide: true,
            count,
            page,
            responsive: 'scroll',
            filter: false,
            selectableRows: false,
            download: false,
            sort: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.searchterm = searchText
                }
                this.dispatch(productsActions.fetch(params))
              }
            }
          }
          return (
            <main>
              {this.props.products.loading && !this.props.products.data.products &&
                <Loading />
              }
              {this.props.products.data.products &&
                <MUIDataTable data={data} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ products: state.products }))(withRouter(Products))
