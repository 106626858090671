import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Layout from './Layout'
import { connect } from 'react-redux'
import Preloader from 'components/main/layouts/preloader/Preloader'

const PrivateRoute = ({ component: Component, name, auth, ...rest }) => {
  if (!auth.checked) {
    return (
      <Preloader />
    )
  } else {
    if (auth.user === null) {
      return (<Redirect to='/login' />)
    }
  }

  return (<Route {...rest} render={(props) => {
    return (
      <Layout name={name}>
        <Component {...props} {...rest} />
      </Layout>
    )
  }} />)
}
export default connect(state => ({ auth: state.auth }))(PrivateRoute)
