import React from 'react'
import logo from '/../resources/img/logo.png'
import style from './Style'
import CircularProgress from '@material-ui/core/CircularProgress'
export default () => (
  <main className={style.wrapper}>
    <img src={logo} />
    <CircularProgress color='secondary' />
  </main>
)
