import { combineReducers } from 'redux'
import authReducer from 'reducers/auth'
import promotionsReducer from 'reducers/revolucharge/promotions'
import ordersReducer from 'reducers/revolucharge/orders'
import notificationsReducer from 'reducers/notifications'
import providersReducer from 'reducers/revolucharge/providers'
import operatorsReducer from 'reducers/revolucharge/operators'
import transfersReducer from 'reducers/revolucharge/transfers'
import productsReducer from 'reducers/revolucharge/products'
import providerOperatorsReducer from 'reducers/revolucharge/providerOperators'
import cysendOperatorsReducer from 'reducers/revolucharge/cysendOperators'
import configReducer from 'reducers/revolucharge/config'

import revolutilityOrdersReducer from 'reducers/revolutility/orders'
import revolutilityProductsReducer from 'reducers/revolutility/products'
import revolutilityConfigReducer from 'reducers/revolutility/config'
import revolutilityBestselelrReducer from 'reducers/revolutility/bestsellers'

import businessInteligenceReducer from 'reducers/businessInteligence'

export default combineReducers({
  auth: authReducer,
  promotions: promotionsReducer,
  orders: ordersReducer,
  notifications: notificationsReducer,
  providers: providersReducer,
  operators: operatorsReducer,
  transfers: transfersReducer,
  products: productsReducer,
  providerOperators: providerOperatorsReducer,
  cysendOperators: cysendOperatorsReducer,
  config: configReducer,
  revolutilityOrders: revolutilityOrdersReducer,
  revolutilityProducts: revolutilityProductsReducer,
  revolutilityConfig: revolutilityConfigReducer,
  revolutilityBestsellers: revolutilityBestselelrReducer,
  businessInteligence: businessInteligenceReducer
})
