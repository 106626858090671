import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import Card from '@material-ui/core/Card'
import { withApi } from 'services/apiContext'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'

const styles = theme => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    container: {
        marginTop: theme.spacing.unit
    },
    loadingButtonWrapper: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }

})

class OrderReports extends React.Component {
    constructor({ api }) {
        console.log(moment)
        super()
        this.api = api;
        const startofMonth = moment().subtract(1, 'month').startOf('month')
        const endOfMonth = moment().startOf('month')

        this.state = {
            loading: false,
            dateRange: { start: startofMonth.format('YYYY-MM-DD'), end: endOfMonth.format('YYYY-MM-DD') },
            formErrors: {}
        }
    }

    componentDidMount() {
    }

    validateForm = () => {
        const errors = {}
        const startMoment = moment(this.state.dateRange.start, 'YYYY-MM-DD')
        const endMoment = moment(this.state.dateRange.end, 'YYYY-MM-DD')
        const isStartValid = startMoment.isValid()
        const isEndValid = endMoment.isValid()
        if(startMoment.isAfter(endMoment)){
            alert('La fecha de inicio no puede ser posterior a la de fin.')
            return;
        }else if (endMoment.diff(startMoment,'months') > 3){
            alert('El rango máximo son 3 meses')
            return;
        }
        if (!isStartValid) {
            errors.start = true
        }
        if (!isEndValid) {
            errors.end = true
        }

        this.setState({ formErrors: errors })
        return Object.values(errors).filter(e => e).length === 0
    }

    getReport = () => {
        const downloadReport = async () => {
            if (this.validateForm()) {
                this.setState({ loading: true })
                try {
                    const result = await this.api.revolucharge.getReports({
                        start: moment(this.state.dateRange.start, 'YYYY-MM-DD').toDate().toISOString(),
                        end: moment(this.state.dateRange.end, 'YYYY-MM-DD').toDate().toISOString()
                    })
                    window.location = result.url
                } catch (e) {
                    alert('Could not build report.')
                } finally {
                    this.setState({ loading: false })
                }
            }
        }
        downloadReport()
    }

    handleInputChange = (evt) => {
        const dateRange = this.state.dateRange
        dateRange[evt.target.name] = evt.target.value
        this.setState({ dateRange })
        this.validateForm()
    }

    render() {
        return (
            <NamespacesConsumer ns={['reports']}>
                {t => {
                    const classes = this.props.classes
                    return (
                        <main>
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={6}>
                                    <Card className={classes.grow}>
                                        <CardContent>
                                            <Typography variant='h6' gutterBottom>
                                                {t('ORDER_REPORTS')}
                                            </Typography>
                                            <TextField
                                                margin='dense'
                                                id='start'
                                                name='start'
                                                error={this.state.formErrors.start}
                                                helperText={this.state.formErrors.start ? t('INVALID_DATE') : null}
                                                value={this.state.dateRange.start}
                                                onChange={this.handleInputChange}
                                                label={t('DATE_RANGE_START')}
                                                type='date'
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            <TextField
                                                margin='dense'
                                                id='end'
                                                name='end'
                                                value={this.state.dateRange.end}
                                                error={this.state.formErrors.end}
                                                helperText={this.state.formErrors.end ? t('INVALID_DATE') : null}
                                                onChange={this.handleInputChange}
                                                label={t('DATE_RANGE_END')}
                                                type='date'
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </CardContent>
                                        <CardActions className={classes.buttonContainer}>
                                            <div className={classes.loadingButtonWrapper}>
                                                <Button
                                                    disabled={this.state.loading}
                                                    onClick={this.getReport}
                                                >
                                                    {t('DOWNLOAD')}
                                                </Button>
                                                {this.state.loading &&
                                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                                }
                                            </div>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </main>
                    )
                }}
            </NamespacesConsumer>
        )
    }
}

export default withApi(withStyles(styles)(OrderReports))
