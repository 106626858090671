import { call, put, takeLatest } from 'redux-saga/effects'
import biActions, { FETCH } from 'actions/businessInteligence'

export default ({ api }) => {
  function * getBi () {
    try {
      const revoluchargeBi = yield call(api.revolucharge.getBi)
      const revolutilityBi = yield call(api.revolutility.getBi)
      yield put(biActions.fetchDone({
        revolucharge: revoluchargeBi,
        revolutility: revolutilityBi
      }))
    } catch (e) {
      console.log(e)
      yield put(biActions.fetchDone({ revolucharge: null, revolutility: null }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getBi)
  }
}
