import { call, put, takeLatest } from 'redux-saga/effects'
import transfersActions, { FETCH, APPROVE } from 'actions/revolucharge/transfers'
import notificationsActions from 'actions/notifications'
import ordersActions from 'actions/revolucharge/orders'

export default ({ api, i18n }) => {
  function * getTransfers ({ payload }) {
    try {
      const transfers = yield call(api.revolucharge.getTransfers, payload)
      yield put(transfersActions.putTransfers({
        count: transfers.count,
        transfers: transfers.data,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(transfersActions.putTransfers({ transfers: [] }))
    }
  }

  function * approve ({ payload }) {
    try {
      yield call(api.revolucharge.approveTransfer, { transferId: payload.transferId })
      yield put(ordersActions.fetchDetails({ id: payload.orderId }))
      yield put(notificationsActions.enqueue({
        message: i18n.t('APPROVE_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('APPROVE_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(transfersActions.approveDone())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getTransfers)
    yield takeLatest(APPROVE, approve)
  }
}
