import { call, put, takeLatest } from 'redux-saga/effects'
import providersActions, { FETCH, TOGGLE } from 'actions/revolucharge/providers'

export default ({ api }) => {
  function * getProviders () {
    try {
      const providers = yield call(api.revolucharge.getProviders)
      yield put(providersActions.fetchDone({ providers }))
    } catch (e) {
      console.log(e)
      yield put(providersActions.fetchDone({ providers: [] }))
    }
  }

  function * toggle ({ payload }) {
    try {
      yield call(api.revolucharge.toggleProvider, payload)
    } catch (e) {
      console.log(e)
    } finally {
      yield put(providersActions.toggleDone())
      yield put(providersActions.fetch())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getProviders)
    yield takeLatest(TOGGLE, toggle)
  }
}
