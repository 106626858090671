import {
  FETCH,
  FETCH_DONE
} from 'actions/revolucharge/providerOperators'

export default (
  state = {
    data: {},
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign(
        {},
        state,
        { loading: true, data: { ...state.data, limit: action.payload.limit } }
      )
    }
    case FETCH_DONE: {
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            providerOperators: action.payload.providerOperators,
            count: action.payload.count,
            page: action.payload.page,
            limit: action.payload.limit
          }
        })
    }
    default: return state
  }
}
