import { all, fork } from 'redux-saga/effects'
import auth from 'sagas/auth'
import promotions from 'sagas/revolucharge/promotions'
import orders from 'sagas/revolucharge/orders'
import providers from 'sagas/revolucharge/providers'
import operators from 'sagas/revolucharge/operators'
import transfers from 'sagas/revolucharge/transfers'
import products from 'sagas/revolucharge/products'
import providerOperators from 'sagas/revolucharge/providerOperators'
import cysendOperators from 'sagas/revolucharge/cysendOperators'
import config from 'sagas/revolucharge/config'

import revolutilityOrders from 'sagas/revolutility/orders'
import revolutilityProducts from 'sagas/revolutility/products'
import revolutilityConfig from 'sagas/revolutility/config'
import revolutilityBestsellers from 'sagas/revolutility/bestsellers'

import businessInteligence from 'sagas/businessInteligence'

export default ({ loginWindow, api, tokens, i18n }) => function * root () {
  yield all([
    fork(auth({ loginWindow, api, tokens })),
    fork(promotions({ api })),
    fork(orders({ api, i18n })),
    fork(providers({ api })),
    fork(operators({ api })),
    fork(transfers({ api, i18n })),
    fork(products({ api })),
    fork(providerOperators({ api })),
    fork(cysendOperators({ api, i18n })),
    fork(config({ api, i18n })),
    fork(revolutilityOrders({ api, i18n })),
    fork(revolutilityProducts({ api, i18n })),
    fork(revolutilityConfig({ api, i18n })),
    fork(revolutilityBestsellers({ api, i18n })),
    fork(businessInteligence({ api }))

  ])
}
