import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import Loading from 'components/shared/loading/Loading'
import configActions from 'actions/revolucharge/config'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import { isEqual } from 'lodash-es'

const styles = theme => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  container: {
    marginTop: theme.spacing.unit
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }

})

class Config extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
    this.state = { formErrors: {} }
  }

  componentDidMount () {
    this.dispatch(configActions.fetch())
  }

  componentDidUpdate (prevProps) {
    if (
      !isEqual(prevProps.config.data, this.props.config.data) || !this.state.config
    ) {
      if (this.props.config.data) {
        this.setState({
          config: this.props.config.data
        })
      }
    }
  }

  updateRewards = () => {
    if (this.validateForm()) {
      this.setState({ lastPressedButton: 'REWARDS' })
      this.dispatch(configActions.update({
        config: {
          revolupayReward: this.state.config.revolupayReward,
          revoluvipBasicReward: this.state.config.revoluvipBasicReward,
          revoluvipSilverReward: this.state.config.revoluvipSilverReward,
          revoluvipGoldReward: this.state.config.revoluvipGoldReward,
          revoluvipDiamondReward: this.state.config.revoluvipDiamondReward,
          maxRevolupayRewards: this.state.config.maxRevolupayRewards
        }
      }))
    }
  }

  updateEmails = () => {
    this.setState({ lastPressedButton: 'EMAILS' })
    this.dispatch(configActions.update({
      config: {
        orderConfirmationMessageEn: this.state.config.orderConfirmationMessageEn,
        orderConfirmationMessageEs: this.state.config.orderConfirmationMessageEs
      }
    }))
  }

  validateForm = () => {
    const errors = {}
    if (isNaN(this.state.config.revolupayReward) || this.state.config.revolupayReward < 0) {
      errors.revolupayReward = true
    } else {
      errors.revolupayReward = false
    }

    if (isNaN(this.state.config.revoluvipBasicReward) || this.state.config.revoluvipBasicReward < 0) {
      errors.revoluvipBasicReward = true
    } else {
      errors.revoluvipBasicReward = false
    }

    if (isNaN(this.state.config.revoluvipSilverReward) || this.state.config.revoluvipSilverReward < 0) {
      errors.revoluvipSilverReward = true
    } else {
      errors.revoluvipSilverReward = false
    }

    if (isNaN(this.state.config.revoluvipGoldReward) || this.state.config.revoluvipGoldReward < 0) {
      errors.revoluvipGoldReward = true
    } else {
      errors.revoluvipGoldReward = false
    }

    if (isNaN(this.state.config.revoluvipDiamondReward) || this.state.config.revoluvipDiamondReward < 0) {
      errors.revoluvipDiamondReward = true
    } else {
      errors.revoluvipDiamondReward = false
    }

    if (isNaN(this.state.config.maxRevolupayRewards) || this.state.config.maxRevolupayRewards < 0) {
      errors.maxRevolupayRewards = true
    } else {
      errors.maxRevolupayRewards = false
    }
    this.setState({ formErrors: errors })
    return Object.values(errors).filter(e => e).length === 0
  }

  handleInputChange = (evt) => {
    const config = this.state.config
    config[evt.target.name] = evt.target.value
    this.setState({ config })
    this.validateForm()
  }

  render () {
    return (
      <NamespacesConsumer ns={['config']}>
        {t => {
          const classes = this.props.classes
          return (
            <main>
              {this.props.config.loading && !this.props.config.data &&
                <Loading />
              }
              {this.state.config &&
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.grow}>
                      <CardContent>
                        <Typography variant='h6' gutterBottom>
                          {t('REWARDS')}
                        </Typography>
                        <TextField
                          margin='dense'
                          id='revolupayReward'
                          name='revolupayReward'
                          value={this.state.config.revolupayReward}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.revolupayReward}
                          helperText={this.state.formErrors.revolupayReward ? t('INVALID_INPUT') : null}
                          label={t('REVOLUPAYREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                          }}
                        />
                        <TextField
                          margin='dense'
                          id='maxRevolupayRewards'
                          name='maxRevolupayRewards'
                          value={this.state.config.maxRevolupayRewards}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.maxRevolupayRewards}
                          helperText={this.state.formErrors.maxRevolupayRewards ? t('INVALID_INPUT') : null}
                          label={t('MAXREVOLUPAYREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>₡CU</InputAdornment>
                          }}
                        />
                        <TextField
                          margin='dense'
                          id='revoluvipBasicReward'
                          name='revoluvipBasicReward'
                          value={this.state.config.revoluvipBasicReward}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.revoluvipBasicReward}
                          helperText={this.state.formErrors.revoluvipBasicReward ? t('INVALID_INPUT') : null}
                          label={t('REVOLUVIPBASICREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                          }}
                        />
                        <TextField
                          margin='dense'
                          id='revoluvipSilverReward'
                          name='revoluvipSilverReward'
                          value={this.state.config.revoluvipSilverReward}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.revoluvipSilverReward}
                          helperText={this.state.formErrors.revoluvipSilverReward ? t('INVALID_INPUT') : null}
                          label={t('REVOLUVIPSILVERREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                          }}
                        />
                        <TextField
                          margin='dense'
                          id='revoluvipGoldReward'
                          name='revoluvipGoldReward'
                          value={this.state.config.revoluvipGoldReward}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.revoluvipGoldReward}
                          helperText={this.state.formErrors.revoluvipGoldReward ? t('INVALID_INPUT') : null}
                          label={t('REVOLUVIPGOLDREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                          }}
                        />
                        <TextField
                          margin='dense'
                          id='revoluvipDiamondReward'
                          name='revoluvipDiamondReward'
                          value={this.state.config.revoluvipDiamondReward}
                          onChange={this.handleInputChange}
                          error={this.state.formErrors.revoluvipDiamondReward}
                          helperText={this.state.formErrors.revoluvipDiamondReward ? t('INVALID_INPUT') : null}
                          label={t('REVOLUVIPDIAMONDREWARD')}
                          type='number'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>%</InputAdornment>
                          }}
                        />
                      </CardContent>
                      <CardActions className={classes.buttonContainer}>
                        <div className={classes.loadingButtonWrapper}>
                          <Button
                            onClick={this.updateRewards}
                            disabled={this.props.config.updating}
                          >
                            {t('UPDATE')}
                          </Button>
                          {this.props.config.updating && this.state.lastPressedButton === 'REWARDS' &&
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          }
                        </div>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.grow}>
                      <CardContent>
                        <Typography variant='h6' gutterBottom>
                          {t('EMAILS')}
                        </Typography>
                        <TextField
                          margin='dense'
                          multiline
                          id='orderConfirmationMessageEn'
                          name='orderConfirmationMessageEn'
                          value={this.state.config.orderConfirmationMessageEn}
                          onChange={this.handleInputChange}
                          label={t('ORDERCONFIRMATIONMESSAGEEN')}
                          type='text'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          margin='dense'
                          multiline
                          id='orderConfirmationMessageEs'
                          name='orderConfirmationMessageEs'
                          value={this.state.config.orderConfirmationMessageEs}
                          onChange={this.handleInputChange}
                          label={t('ORDERCONFIRMATIONMESSAGEES')}
                          type='text'
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </CardContent>
                      <CardActions className={classes.buttonContainer}>
                        <div className={classes.loadingButtonWrapper}>
                          <Button
                            disabled={this.props.config.updating}
                            onClick={this.updateEmails}
                          >
                            {t('UPDATE')}
                          </Button>
                          {this.props.config.updating && this.state.lastPressedButton === 'EMAILS' &&
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          }
                        </div>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(
  state => ({ config: state.config })
)(withStyles(styles)(Config))
