import React from 'react'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import providerOperatorsActions from 'actions/revolucharge/providerOperators'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'

const initialRowsPerPage = 15

class ProviderOperators extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
  }

  componentDidMount () {
    this.dispatch(providerOperatorsActions.fetch({ limit: initialRowsPerPage }))
  }

  render () {
    return (
      <NamespacesConsumer ns={['broker']}>
        {t => {
          const columns = [
            {
              name: t('PROVIDER')
            },
            {
              name: t('COUNTRY')
            },
            {
              name: t('OPERATOR')
            },
            {
              name: t('RETAIL_RATIO'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Tooltip title={t('RETAIL_RATION_TOOLTIP')}>
                    <span>
                      <CurrencyFormat value={value * 100} currencyIso={'%'} />
                    </span>
                  </Tooltip>
                )
              }
            },
            {
              name: t('COMMISSION_RATIO'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Tooltip title={t('COMMISSION_RATION_TOOLTIP')}>
                    <span>
                      <CurrencyFormat value={value * 100} currencyIso={'%'} />
                    </span>
                  </Tooltip>
                )
              }
            },
            {
              name: t('UPDATED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateTimeFormat value={new Date(value)} />
                )
              }
            }
          ]
          let data = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.providerOperators.data.providerOperators) {
            count = this.props.providerOperators.data.count
            page = this.props.providerOperators.data.page
            rowsPerPage = this.props.providerOperators.data.limit
            data = this.props.providerOperators.data.providerOperators.map(t => ([
              t.provider.name,
              t.country.name,
              t.operator.name,
              t.retailRatio,
              t.commissionRatio,
              t.updatedAt
            ]))
          }
          const options = {
            serverSide: true,
            count,
            page,
            download: false,
            responsive: 'scroll',
            filter: false,
            selectableRows: false,
            sort: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.searchterm = searchText
                }
                this.dispatch(providerOperatorsActions.fetch(params))
              }
            }
          }
          return (
            <main>
              {this.props.providerOperators.loading && !this.props.providerOperators.data.providerOperators &&
                <Loading />
              }
              {this.props.providerOperators.data.providerOperators &&
                <MUIDataTable data={data} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ providerOperators: state.providerOperators }))(withRouter(ProviderOperators))
