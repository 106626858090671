import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withRouter } from 'react-router-dom'
import Loading from 'components/shared/loading/Loading'
import ordersActions from 'actions/revolucharge/orders'
import transfersActions from 'actions/revolucharge/transfers'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import ObjectDebug from 'components/shared/ObjectDebug'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  container: {
    marginTop: theme.spacing.unit
  },
  notFound: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }

})

const Transition = (props) => {
  return (<Slide direction='up' {...props} />)
}

class OrderDetail extends React.Component {
  constructor ({ dispatch, match, history }) {
    super()
    this.dispatch = dispatch
    this.orderId = match.params.id
    this.history = history
    this.state = {
      transferDetails: null,
      transferId: null,
      refundAlert: false,
      notify: false,
      approveAlert: false,
      form: { email: '', error: null }
    }
  }

  componentDidMount () {
    this.dispatch(ordersActions.fetchDetails({ id: this.orderId }))
  }

  handleModalClose = () => {
    this.setState({
      transferDetails: null,
      refundAlert: false,
      approveAlert: false,
      notify: false,
      form: { email: '', error: null }
    })
  }

  showTransferDetailsModal = (details) => {
    this.setState({ transferDetails: details })
  }

  showRefundAlert = () => {
    this.setState({ refundAlert: true })
  }

  showApproveAlert = (transferId) => {
    this.setState({ approveAlert: true, transferId })
  }

  showNotificationWindow = () => {
    this.setState({ notify: true, form: { email: '', error: null } })
  }

  updateEmailValue = (evt) => {
    this.setState({
      form: { email: evt.target.value, error: null }
    })
  }

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  notify = () => {
    if (!this.validateEmail(this.state.form.email)) {
      this.setState({ form: { email: this.state.form.email, error: true } })
    } else {
      this.dispatch(ordersActions.notify({ id: this.orderId, email: this.state.form.email }))
      this.setState({ notify: false })
    }
  }

  isRefundable = () => {
    return this.props.orders.data.details.payments.filter(p => p.status === 'SUCCESS').length > 0
  }

  isPending = () => {
    return this.props.orders.data.details.payments.filter(p => (p.status === 'SUCCESS' || p.status === 'REFUNDED')).length === 0
  }

  approve = () => {
    this.dispatch(transfersActions.approve({
      transferId: this.state.transferId, orderId: this.orderId
    }))
    this.setState({ approveAlert: false })
  }

  refund = () => {
    this.dispatch(ordersActions.refund({ id: this.orderId }))
    this.setState({ refundAlert: false })
  }

  render () {
    return (
      <NamespacesConsumer ns={['orders']}>
        {t => {
          const classes = this.props.classes
          const details = this.props.orders.data.details
          return (
            <main>
              <Dialog
                open={this.state.transferDetails !== null}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleModalClose}
              >
                <DialogTitle>
                  {t('TRANSFER_DETAILS')}
                </DialogTitle>
                <DialogContent>
                  <ObjectDebug object={this.state.transferDetails} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} color='primary'> {t('CLOSE')} </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.refundAlert || this.props.orders.refunding}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('CONFIRMATION_REQUIRED')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('REFUND_ALERT_DESCRIPTION')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} disabled={this.props.orders.refunding}>
                    {t('CANCEL')}
                  </Button>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      onClick={this.refund}
                      disabled={this.props.orders.refunding}
                      color='primary'
                      autoFocus>
                      {t('REFUND')}
                    </Button>
                    {this.props.orders.refunding &&
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    }
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.approveAlert || this.props.transfers.approving}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('CONFIRMATION_REQUIRED')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('APPROVE_ALERT_DESCRIPTION')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} disabled={this.props.transfers.approving}>
                    {t('CANCEL')}
                  </Button>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      onClick={this.approve}
                      disabled={this.props.transfers.approving}
                      color='primary'
                      autoFocus>
                      {t('APPROVE')}
                    </Button>
                    {this.props.transfers.approving &&
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    }
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.notify || this.props.orders.notifying}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('NOTIFICATION_TITLE')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('NOTIFICATION_DISCLOSURE')}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    error={this.state.form.error}
                    helperText={this.state.form.error ? t('INVALID_EMAIL_ADDRESS') : null}
                    value={this.state.form.email}
                    onChange={this.updateEmailValue}
                    margin='dense'
                    id='email'
                    label={t('EMAIL')}
                    type='email'
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} disabled={this.props.orders.notifying}>
                    {t('CANCEL')}
                  </Button>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      onClick={this.notify}
                      disabled={this.props.orders.notifying}
                      color='primary'
                      autoFocus>
                      {t('SEND')}
                    </Button>
                    {this.props.orders.notifying &&
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    }
                  </div>
                </DialogActions>
              </Dialog>
              <div>
                <IconButton color='primary' onClick={() => {
                  this.history.goBack()
                }}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              {this.props.orders.loading && !this.props.orders.data.details &&
                <Loading />
              }
              {!this.props.orders.loading && this.props.orders.data.details === null &&
                <div className={classes.notFound}>
                  <Typography variant='h2'>
                    {t('ORDER_NOT_FOUND_ERROR')}
                  </Typography>
                </div>
              }
              {this.props.orders.data.details &&
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.root}>
                      <Grid container spacing={24} className={classes.container}>
                        <Grid item xs className={classes.flex}>
                          <Card className={classes.grow}>
                            <CardContent>
                              <Typography variant='h6' gutterBottom>
                                {t('ORDER')}
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('ID')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {details.order.id}
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('AMOUNT')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                <CurrencyFormat value={details.order.amount} currencyIso='₡CU' />
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('REWARD')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {
                                  (() => {
                                    if (details.order.rewardType) {
                                      return `${details.order.reward}% ${details.order.rewardType}`
                                    } else {
                                      return '--'
                                    }
                                  })()
                                }
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('CREATION_DATE')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                <DateTimeFormat value={new Date(details.order.createdAt)} />
                              </Typography>
                            </CardContent>
                            <CardActions className={classes.buttonContainer}>
                              <Button
                                onClick={this.showNotificationWindow}
                                disabled={this.isPending()}
                              >
                                {t('SEND_ORDER_DETAILS')}
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                        <Grid item xs className={classes.flex}>
                          <Card className={classes.grow}>
                            <CardContent>
                              <Typography variant='h6' gutterBottom>
                                {t('CUSTOMER')}
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('REVOLUPAY_USER')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {details.user ? (details.user.revolupayUserId ? details.user.revolupayUserId : '--') : '--'}
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('NAME')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {details.user &&
                                  `${details.user.name} ${details.user.surname}`
                                }
                                {details.user === null &&
                                  '-'
                                }
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('EMAIL')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {details.user ? details.user.email : '-'}
                              </Typography>
                              <Typography variant='subtitle1'>
                                {t('PHONE')}
                              </Typography>
                              <Typography variant='body1' color='textSecondary'>
                                {details.user ? details.user.phone : '-'}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.root}>
                      <div className={classes.flex}>
                        <Typography className={classes.grow} variant='h6' gutterBottom>
                          {t('ITEMS')}
                        </Typography>
                      </div>
                      <Grid container spacing={24} className={classes.container}>
                        {details.products.map(p => (
                          <Grid
                            key={`${p.beneficiary}-${p.product.reference}`}
                            item
                            xs
                            className={classes.flex}>
                            <Card className={classes.grow}>
                              <CardContent>
                                <Typography variant='subtitle1'>
                                  {t('BENEFICIARY')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {`+${p.country.prefix}${p.beneficiary}`}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('COUNTRY')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.country.name}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('OPERATOR')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.operator.name}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('CUSTOMER_CARE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.operator.careNumber ? p.operator.careNumber : '-'}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('PROVIDER')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.provider.name}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('PRODUCT')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {`${p.product.reference} - ${p.product.name}`}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('AMOUNT')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <CurrencyFormat value={p.amount} currencyIso='₡CU' />
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('SEND_AMOUNT')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <CurrencyFormat
                                    value={p.sendValue}
                                    currencyIso={p.product.sendCurrency} />
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('RECEIVE_AMOUNT')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <CurrencyFormat
                                    value={p.receiveValue}
                                    currencyIso={p.product.receiveCurrency} />
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('TRANSFER_REFERENCE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {`${p.transfer ? p.transfer.code : '-'} / ${p.transfer ? p.transfer.reference ? p.transfer.reference : '-' : '-'}`}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('TRANSFER_APPROVED')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {
                                    (() => {
                                      if (p.transfer) {
                                        if (p.transfer.approved) {
                                          return (
                                            <Chip label={t('YES')} color='secondary' />
                                          )
                                        } else {
                                          return (
                                            <Chip label={t('NO')} color='primary' />
                                          )
                                        }
                                      } else {
                                        return '-'
                                      }
                                    })()
                                  }
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('TRANSFER_STATUS')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {
                                    (() => {
                                      if (p.transfer) {
                                        if (p.transfer.status === 'SUCCESS') {
                                          return (
                                            <Chip label={t(p.transfer.status)} color='secondary' />
                                          )
                                        } else if (p.transfer.status === 'ERROR') {
                                          return (
                                            <Chip label={t(p.transfer.status)} />
                                          )
                                        } else {
                                          return (
                                            <Chip label={t(p.transfer.status)} color='primary' />
                                          )
                                        }
                                      } else {
                                        return '-'
                                      }
                                    })()
                                  }
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('UPDATE_DATE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <DateTimeFormat value={new Date(p.transfer.updatedAt)} />
                                </Typography>
                              </CardContent>
                              <CardActions className={classes.buttonContainer}>
                                <Button
                                  disabled={p.transfer.approved}
                                  onClick={() => {
                                    this.showApproveAlert(p.transfer.id)
                                  }}>{t('APPROVE')}</Button>
                                <Button disabled={p.transfer.response === null} onClick={() => {
                                  this.showTransferDetailsModal(p.transfer.response)
                                }}>{t('TRANSFER_DETAILS')}</Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.root}>
                      <div className={classes.flex}>
                        <Typography className={classes.grow} variant='h6' gutterBottom>
                          {t('PAYMENT_DETAILS')}
                        </Typography>
                      </div>
                      <Grid container spacing={24} className={classes.container}>
                        {details.payments.map(p => (
                          <Grid key={p.id} item xs className={classes.flex}>
                            <Card className={classes.grow}>
                              <CardContent>
                                <Typography variant='subtitle1'>
                                  {t('ID')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.id}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('TYPE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.type}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('REFERENCE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {p.reference}
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('STATUS')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  {
                                    (() => {
                                      if (p.status === 'SUCCESS') {
                                        return (
                                          <Chip label={t(p.status)} color='secondary' />
                                        )
                                      } else if (p.status === 'REFUNDED') {
                                        return (
                                          <Chip label={t(p.status)} />
                                        )
                                      } else {
                                        return (
                                          <Chip label={t(p.status)} color='primary' />
                                        )
                                      }
                                    })()
                                  }
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('CREATION_DATE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <DateTimeFormat value={new Date(p.createdAt)} />
                                </Typography>
                                <Typography variant='subtitle1'>
                                  {t('UPDATE_DATE')}
                                </Typography>
                                <Typography variant='body1' color='textSecondary'>
                                  <DateTimeFormat value={new Date(p.updatedAt)} />
                                </Typography>
                              </CardContent>
                              <CardActions className={classes.buttonContainer}>
                                <Button
                                  disabled={this.props.orders.refunding || p.status !== 'SUCCESS'}
                                  onClick={this.showRefundAlert}
                                >
                                  {t('REFUND')}
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(
  state => ({ orders: state.orders, transfers: state.transfers })
)(withRouter(withStyles(styles)(OrderDetail)))
