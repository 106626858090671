export const FETCH = '@revolucharge/promotions/fetch'
export const HIGHLIGHT = '@revolucharge/promotions/highlight'
export const FETCH_DONE = '@revolucharge/promotions/fetch_done'

export default {
  fetch: () => ({
    type: FETCH
  }),
  putPromotions: ({ promotions }) => ({
    type: FETCH_DONE,
    payload: {
      promotions
    }
  }),
  highlight: ({ id, highlight }) => ({
    type: HIGHLIGHT,
    payload: {
      id, highlight
    }
  })
}
