import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { withApi } from 'services/apiContext'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import ProductSelect from './ProductSelect'
import { FormControl, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios'

const styles = theme => ({
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  progressContainer: {
    display: 'flex',
    width: '100%',
    marginTop: 20,
    justifyContent: 'center'
  },
  dropZone: {
    marginTop: 20,
    backgroundColor: 'inherit'
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2
  }
})

class AddBatchOrderModal extends React.Component {
  constructor ({ history, api }) {
    super()
    this.api = api
    this.history = history
    this.state = {
      loading: false,
      name: '',
      product: null,
      batchOrderId: null,
      nameError: false,
      uploadLink: null
    }
    this.handleModalClose = this.handleModalClose.bind(this)
    this.createUploadLink = this.createUploadLink.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
  }

  handleModalClose () {
    this.setState({
      loading: false,
      product: null,
      name: '',
      nameError: false,
      uploadLink: null
    })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  componentDidMount () {
  }

  handleFileUpload (files) {
    this.upload(files[files.length - 1])
  }

  upload (file) {
    const run = async () => {
      this.setState({ loading: true })
      const options = {
        headers: { 'Content-Type': file.type }
      }
      try {
        await axios.put(this.state.uploadLink, file, options)
        if (this.props.onSuccess) {
          this.props.onSuccess(this.state.batchOrderId)
        }
      } catch (e) {
        alert('Could not upload file, try again.')
      }
      this.setState({ loading: false })
    }
    run()
  }

  createUploadLink () {
    if (this.state.name.length === 0) {
      this.setState({ nameError: true })
      return
    }
    const run = async () => {
      this.setState({ loading: true, nameError: false })
      try {
        const response = await this.api.revolucharge.addBatchOrder({
          productId: this.state.product.value,
          name: this.state.name
        })
        this.setState({ uploadLink: response.url, batchOrderId: response.batchOrderId })
      } catch (e) {
        alert('Could not create upload link...')
        console.error(e)
      }
      this.setState({ loading: false })
    }
    run()
  }

  render () {
    return (
      <NamespacesConsumer ns={['batchOrders']}>
        {t => {
          const classes = this.props.classes
          const props = this.props
          return (
            <React.Fragment>
              {this.props.open &&
                <Dialog
                  open={props.open}
                  onClose={this.handleModalClose}
                >
                  <DialogTitle>{t('ADD_BATCH_ORDER')}</DialogTitle>
                  <DialogContent>
                    {this.state.uploadLink === null &&
                      <React.Fragment>
                        <FormControl fullWidth className={classes.formControl}>
                          <TextField
                            error={this.state.nameError}
                            helperText={t('NAME_HELPERTEXT')}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={this.state.name}
                            onChange={event => { this.setState({ name: event.target.value }) }}
                            label={t('NAME')}
                            type='text'
                          />

                        </FormControl>
                        <ProductSelect onValue={(product) => {
                          this.setState({ product })
                        }} />
                      </React.Fragment>
                    }
                    {this.state.uploadLink &&
                      <React.Fragment>
                        <Typography variant='subtitle2'>
                          {t('NAME')}
                        </Typography>
                        <Typography variant='body1'>
                          {this.state.name}
                        </Typography>
                        <Typography variant='subtitle2'>
                          {t('PRODUCT')}
                        </Typography>
                        <Typography variant='body1'>
                          {this.state.product.label}
                        </Typography>
                        {this.state.loading &&
                          <div className={classes.progressContainer}>
                            <CircularProgress />
                          </div>
                        }
                        {!this.state.loading &&
                          <DropzoneArea
                            dropzoneClass={classes.dropZone}
                            acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                            filesLimit={1}
                            maxFileSize={100000}
                            showPreviews={false}
                            showFileNamesInPreview={false}
                            showPreviewsInDropzone={false}
                            dropzoneText={t('UPLOAD_INSTRUCTIONS')}
                            onChange={this.handleFileUpload}
                          />
                        }
                      </React.Fragment>
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleModalClose} >
                      {t('CANCEL')}
                    </Button>
                    {this.state.uploadLink === null &&
                      <div className={classes.loadingButtonWrapper}>
                        <Button
                          onClick={this.createUploadLink}
                          disabled={this.state.loading || this.state.product === null}
                          color='primary'
                          autoFocus>
                          {t('NEXT')}
                        </Button>
                        {this.state.loading &&
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        }
                      </div>
                    }
                  </DialogActions>
                </Dialog>
              }
            </React.Fragment>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default withApi(withStyles(styles)(AddBatchOrderModal))
