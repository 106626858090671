export const FETCH = '@revolucharge/providers/fetch'
export const FETCH_DONE = '@revolucharge/providers/fetch_done'
export const TOGGLE = '@revolucharge/providers/toggle'
export const TOGGLE_DONE = '@revolucharge/providers/toggle_done'

export default {
  fetch: () => ({
    type: FETCH,
    payload: {}
  }),
  fetchDone: ({ providers }) => ({
    type: FETCH_DONE,
    payload: {
      providers
    }
  }),
  toggle: ({ id, disabled }) => ({
    type: TOGGLE,
    payload: {
      id, disabled
    }
  }),
  toggleDone: () => ({
    type: TOGGLE_DONE,
    payload: { }

  })
}
