import {
  FETCH,
  FETCH_DONE,
  ADD,
  ADD_DONE,
  DELETE,
  DELETE_DONE
} from 'actions/revolutility/bestsellers'

export default (
  state = {
    data: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign(
        {},
        state,
        { loading: true }
      )
    }
    case FETCH_DONE: {
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: action.payload.bestsellers
        })
    }
    case ADD: {
      return Object.assign(
        {},
        state,
        { loading: true }
      )
    }
    case ADD_DONE: {
      return Object.assign(
        {},
        state,
        { loading: false }
      )
    }
    case DELETE: {
      return Object.assign(
        {},
        state,
        { loading: true }
      )
    }
    case DELETE_DONE: {
      return Object.assign(
        {},
        state,
        { loading: false }
      )
    }
    default: return state
  }
}
