import React from 'react'
import Router from 'components/main/Router'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import reducer from 'reducers/rootReducer'
import saga from 'sagas/rootSaga'
import loginWindow from 'services/loginWindow'
import api from 'services/api'
import Tokens from 'services/tokens'
import i18n from '../i18n'
import { I18nextProvider } from 'react-i18next'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { ApiProvider } from 'services/apiContext'

const REVOLUCHARGE_API_URL = process.env.REVOLUCHARGE_API_URL
const REVOLUTILITY_API_URL = process.env.REVOLUTILITY_API_URL
const OAUTH_LOGIN = process.env.OAUTH_LOGIN
const JWT_KEY_ENDPOINT = process.env.JWT_KEY_ENDPOINT

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let middleware = null
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(sagaMiddleware)
} else {
  middleware = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const store = createStore(
  reducer,
  middleware
)
const tokens = Tokens({ jwtKeysUrl: JWT_KEY_ENDPOINT })
const Api = api({
  revoluchargeUrl: REVOLUCHARGE_API_URL,
  fetch,
  tokens,
  revolutilityUrl: REVOLUTILITY_API_URL
})
sagaMiddleware.run(saga({
  loginWindow: loginWindow({ url: OAUTH_LOGIN, width: 400, height: 450 }),
  api: Api,
  tokens,
  i18n
}))

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: '#6f91e1',
      main: '#3964af',
      dark: '#003a7f',
      contrastText: '#fff'
    },
    secondary: {
      light: '#f1ea65',
      main: '#bcb833',
      dark: '#898800',
      contrastText: '#000'
    }
  },
  overrides: {
    MUIDataTable: {
      paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      },
      responsiveScroll: {
        flexGrow: 1,
        height: 'auto',
        maxHeight: 'auto'
      }
    }
  }
})

const App = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ApiProvider value={Api}>
          <Router />
        </ApiProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  </Provider>
)

export default App
