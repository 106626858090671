
import React from 'react'
import ErrorBoundary from '../../ErrorBoundary'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import SettingsIcon from '@material-ui/icons/Settings'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import TableChart from '@material-ui/icons/TableChart'
import Gavel from '@material-ui/icons/Gavel'
import Widgets from '@material-ui/icons/Widgets'
import Send from '@material-ui/icons/Send'
import CardGiftcard from '@material-ui/icons/CardGiftcard'
import Work from '@material-ui/icons/Work'
import SignalCellular from '@material-ui/icons/SignalCellular4Bar'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import gravatar from 'gravatar'
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import authActions from 'actions/auth'
import logo from '/../resources/img/logo.png'
import { NamespacesConsumer } from 'react-i18next'
import { SnackbarProvider } from 'notistack'
import Notifier from 'components/shared/Notifier'
import Grow from '@material-ui/core/Grow'
import StarIcon from '@material-ui/icons/Star'
import ListIcon from '@material-ui/icons/List'
import DescriptionIcon from '@material-ui/icons/Description'

const { OAUTH_LOGOUT } = process.env

const drawerWidth = 240
const style = theme => ({
  main: {
    display: 'flex',
    height: '100%'
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  draweSectionTitle: {
    padding: '10px 0 5px 20px'

  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  toolbar: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbarSpacer: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '95%',
    maxHeight: '100%'
  },
  expansionDetails: {
    padding: 0
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2 + 56,
    '@media (min-width:0px) and (orientation: landscape)': {
      paddingTop: theme.spacing.unit * 2 + 48
    },
    '@media (min-width:600px)': {
      paddingTop: theme.spacing.unit * 2 + 64
    }
  }
})

const ListItemLink = ({ icon, primary, to, click }) => {
  const renderLink = itemProps => <Link to={to} {...itemProps} />
  return (
    <ListItem button onClick={click} component={renderLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const SnackbarTransition = (props) => (
  <Grow {...props} />
)
class Layout extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
    this.state = { mobileOpen: false, anchorEl: null }
  }

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }))
  }

  drawer = ({ t, mobile = false } = {}) => {
    const { classes } = this.props
    return (
      <React.Fragment>
        <div className={classes.toolbarSpacer}>
          <img className={classes.logo} src={logo} />
        </div>
        <Divider />
        <List>
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/dashboard'
            primary={t('DASHBOARD')}
            icon={<Widgets />}
          />
        </List>
        <Divider />
        <Typography className={classes.draweSectionTitle} variant='h6'>RevoluCHARGE</Typography>
        <Divider />
        <List>
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/orders'
            primary={t('ORDERS')}
            icon={<ShoppingCart />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/transfers'
            primary={t('TRANSFERS')}
            icon={<Send />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/batchorders'
            primary={t('BATCH_ORDERS')}
            icon={<ListIcon />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/promotions'
            primary={t('PROMOTIONS')}
            icon={<CardGiftcard />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/providers'
            primary={t('PROVIDERS')}
            icon={<Work />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/operators'
            primary={t('OPERATORS')}
            icon={<SignalCellular />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/cysend'
            primary={t('CYSEND_MAPPING')}
            icon={<TableChart />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/products'
            primary={t('PRODUCTS')}
            icon={<Widgets />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/broker'
            primary={t('BROKER')}
            icon={<Gavel />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/config'
            primary={t('CONFIG')}
            icon={<SettingsIcon />}
          />
           <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolucharge/reports'
            primary={t('REPORTS')}
            icon={<DescriptionIcon />}
          />
        </List>
        <Divider />
        <Typography className={classes.draweSectionTitle} variant='h6'>RevolUTILITY</Typography>
        <Divider />
        <List>
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolutility/orders'
            primary={t('ORDERS')}
            icon={<ShoppingCart />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolutility/products'
            primary={t('PRODUCTS')}
            icon={<Widgets />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolutility/bestsellers'
            primary={t('BESTSELLERS')}
            icon={<StarIcon />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolutility/config'
            primary={t('CONFIG')}
            icon={<SettingsIcon />}
          />
          <ListItemLink
            click={() => mobile && this.handleDrawerToggle()}
            to='/revolutility/reports'
            primary={t('REPORTS')}
            icon={<DescriptionIcon />}
          />
        </List>
      </React.Fragment >
    )
  }

  render () {
    const { classes, name, auth } = this.props
    const { anchorEl } = this.state
    const user = auth.user
    const isMenuOpen = Boolean(anchorEl)
    return (
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={SnackbarTransition}
      >
        <NamespacesConsumer ns={['layout']}>
          {t => (
            <div className={classes.main}>
              <Notifier />
              <AppBar position='fixed' className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    color='inherit'
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant='h6' color='inherit' className={classes.grow}>
                    {name}
                  </Typography>
                  <IconButton
                    aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                    aria-haspopup='true'
                    onClick={this.handleProfileMenuOpen}
                    color='inherit'
                  >
                    <Avatar alt={user.name} src={gravatar.url(user.email, { d: 'identicon' })} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMenuOpen}
                    onClose={this.handleProfileMenuClose}
                  >
                    <MenuItem onClick={() => {
                      this.handleProfileMenuClose()
                      window.location.href = OAUTH_LOGOUT
                      this.dispatch(authActions.logout())
                    }}>{t('LOGOUT')}</MenuItem>
                  </Menu>
                </Toolbar>
              </AppBar>
              <nav className={classes.drawer}>
                <Drawer
                  container={this.props.container}
                  variant='temporary'
                  anchor={'left'}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{ paper: classes.drawerPaper }}
                  ModalProps={{ keepMounted: true }}
                >
                  {this.drawer({ t, mobile: true })}
                </Drawer>
                <Hidden xsDown implementation='css'>
                  <Drawer
                    variant='permanent'
                    className={classes.drawer}
                    classes={{ paper: classes.drawerPaper }}
                  >
                    {this.drawer({ t })}
                  </Drawer>
                </Hidden>
              </nav>
              <div className={classes.content}>
                <ErrorBoundary>
                  {this.props.children}
                </ErrorBoundary>
              </div>
            </div>
          )}
        </NamespacesConsumer>
      </SnackbarProvider >
    )
  }
}

export default connect(state => ({ auth: state.auth }))(withStyles(style, { withTheme: true })(Layout))
