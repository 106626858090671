import { call, put, takeLatest } from 'redux-saga/effects'
import ordersActions, { FETCH, FETCH_DETAILS, REFUND, NOTIFY } from 'actions/revolucharge/orders'
import notificationsActions from 'actions/notifications'

export default ({ api, i18n }) => {
  function * getOrders ({ payload }) {
    try {
      const orders = yield call(api.revolucharge.getOrders, payload)
      yield put(ordersActions.putOrders({
        count: orders.count,
        orders: orders.data,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(ordersActions.putOrders({ orders: [] }))
    }
  }

  function * getDetails ({ payload }) {
    try {
      const order = yield call(api.revolucharge.getOrderDetails, payload)
      yield put(ordersActions.putDetails({ details: order }))
    } catch (e) {
      console.log(e)
      yield put(ordersActions.putDetails({ details: null }))
    }
  }

  function * refund ({ payload }) {
    try {
      yield call(api.revolucharge.refundOrder, { orderId: payload.id })
      yield put(ordersActions.fetchDetails(payload))
      yield put(notificationsActions.enqueue({
        message: i18n.t('REFUND_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('REFUND_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(ordersActions.refundDone())
    }
  }

  function * notify ({ payload }) {
    try {
      yield call(api.revolucharge.resendConfirmation, { orderId: payload.id, email: payload.email })
      yield put(notificationsActions.enqueue({
        message: i18n.t('NOTIFICATION_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('NOTIFICATION_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(ordersActions.notifyDone())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getOrders)
    yield takeLatest(FETCH_DETAILS, getDetails)
    yield takeLatest(REFUND, refund)
    yield takeLatest(NOTIFY, notify)
  }
}
