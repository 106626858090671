import jwksClient from 'jwks-rsa'
import jwt from 'jsonwebtoken'

export default ({ jwtKeysUrl }) => {
  const getKey = (header, callback) => {
    const client = jwksClient(
      { strictSsl: false, cache: true, jwksUri: jwtKeysUrl }
    )
    client.getSigningKey(header.kid, function (err, key) {
      if (err) callback(err)
      else {
        var signingKey = key.publicKey || key.rsaPublicKey
        callback(null, signingKey)
      }
    })
  }
  return ({
    get: () => JSON.parse(window.localStorage.getItem('auth')),
    put: (tokens) => window.localStorage.setItem('auth', JSON.stringify(tokens)),
    remove: () => window.localStorage.removeItem('auth'),
    decode: token => new Promise((resolve, reject) => {
      jwt.verify(token, getKey, (err, decoded) => {
        if (err) reject(err)
        else {
          if (!decoded['cognito:groups'].includes('admins')) {
            reject(new Error('Token does not belong to admins group'))
          } else {
            resolve(decoded)
          }
        }
      })
    })
  })
}
