
import React from 'react'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { NamespacesConsumer } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { FormControl } from '@material-ui/core'
import { connect } from 'react-redux'
import operatorsActions from 'actions/revolucharge/operators'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  loadingMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    left: 2,
    fontSize: 16
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2
  },
  paper: {
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function LoadingMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.loadingMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <FormControl fullWidth error={props.selectProps.error} className={props.selectProps.classes.formControl}>
      <TextField
        fullWidth
        error={props.selectProps.error}
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
      <FormHelperText>{props.selectProps.helperText}</FormHelperText>
    </FormControl>
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue (props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
  )
}

function Menu (props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage
}

class SearchableSelect extends React.Component {
  componentDidMount () {
    this.fetchSuggestions(this.props.initialSearch)
  }

  fetchSuggestions = input => {
    this.props.dispatch(operatorsActions.fetch({ limit: 10, offset: 0, searchterm: input }))
  }

  render () {
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    let suggestions = []
    if (this.props.operators.data.operators) {
      suggestions = this.props.operators.data.operators.map(op => ({
        label: op.name,
        value: op.id
      }))
    }

    return (
      <NamespacesConsumer ns={['cysendOperators']}>
        {t => (
          <div className={classes.root}>
            <Select
              classes={classes}
              styles={selectStyles}
              options={suggestions}
              components={components}
              textFieldProps={{
                label: t('OPERATOR'),
                InputLabelProps: {
                  shrink: true
                }
              }}
              error={this.props.error}
              value={this.props.value}
              onInputChange={this.fetchSuggestions}
              onChange={this.props.onValue}
              helperText={t('OPERATOR_SELECT_HELPERTEXT')}
              isLoading={this.props.operators.loading}
              placeholder={t('OPERATOR_SELECT_PLACEHOLDER')}
              isClearable
            />
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}

export default connect(
  state => ({ operators: state.operators })
)(withStyles(styles, { withTheme: true })(SearchableSelect))
