import { call, put, takeLatest } from 'redux-saga/effects'
import productsActions, { FETCH } from 'actions/revolucharge/products'

export default ({ api }) => {
  function * getProducts ({ payload }) {
    try {
      const products = yield call(api.revolucharge.getProducts, payload)
      yield put(productsActions.putProducts({
        count: products.count,
        products: products.data,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(productsActions.putProducts({ products: [] }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getProducts)
  }
}
