import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import Chip from '@material-ui/core/Chip'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'
import { withApi } from 'services/apiContext'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import AddBatchModal from './AddBatchModal'

const styles = theme => ({
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  }

})

class BatchOrders extends React.Component {
  constructor ({ history, api }) {
    super()
    this.api = api
    this.history = history
    this.state = {
      loading: true,
      modalOpen: false,
      batchOrders: []
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    const run = async (api) => {
      let batchOrders = []
      try {
        batchOrders = (await api.revolucharge.listBatchOrders()).map(c => ([
          c.id,
          c.status,
          c.name,
          `${c.providerName} - ${c.operatorName} - ${c.productName}`,
          c.rows,
          { amount: c.sendAmount, currency: c.sendCurrency },
          { amount: c.receiveAmount, currency: c.receiveCurrency },
          c.createdAt,
          c.id
        ]))
      } catch (e) {
        console.log(e)
      }
      this.setState({ loading: false, batchOrders })
    }
    run(this.api)
  }

  render () {
    return (
      <NamespacesConsumer ns={['batchOrders']}>
        {t => {
          const columns = [
            {
              name: t('ID')
            },
            {
              name: t('STATUS'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  let color = 'default'
                  value = value.toUpperCase()
                  if (value === 'FINISHED') {
                    color = 'secondary'
                  }
                  if (value === 'PROCESSED' || value === 'ENQUEUED') {
                    color = 'primary'
                  }
                  return (
                    <Chip label={t(value)} color={color} />
                  )
                }
              }
            },
            {
              name: t('NAME')
            },
            {
              name: t('PRODUCT')
            },
            {
              name: t('ROWS')
            },
            {
              name: t('SEND_AMOUNT'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('RECEIVE_AMOUNT'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('CREATED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateTimeFormat value={new Date(value)} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (<IconButton onClick={() => {
                    this.history.push(`/revolucharge/batchOrder/${value}`)
                  }}>
                    <LaunchIcon />
                  </IconButton>
                  )
                }
              }
            }
          ]
          const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            selectableRows: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 50, 100]
          }
          const classes = this.props.classes
          return (
            <main>
              <AddBatchModal
                open={this.state.modalOpen}
                onClose={() => {
                  this.setState({ modalOpen: false })
                }}
                onSuccess={(batchOrderId) => {
                  this.history.push(`/revolucharge/batchOrder/${batchOrderId}`)
                }}
              />
              {this.state.loading &&
                <Loading />
              }
              {!this.state.loading &&
                <React.Fragment>
                  <div className={classes.actionBar}>
                    <Tooltip title={t('NEW_BATCH')}>
                      <IconButton color='primary' onClick={() => {
                        this.setState({ modalOpen: true })
                      }}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <MUIDataTable data={this.state.batchOrders} columns={columns} options={options} />
                </React.Fragment>
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default withRouter(withApi(withStyles(styles)(BatchOrders)))
