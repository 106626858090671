import {
  FETCH, FETCH_DONE, TOGGLE, TOGGLE_DONE
} from 'actions/revolucharge/providers'

export default (
  state = {
    data: {},
    toggling: false,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign({}, state, { loading: true })
    }
    case FETCH_DONE: {
      return Object.assign({}, state, { loading: false, data: { providers: action.payload.providers } })
    }
    case TOGGLE: {
      return Object.assign({}, state, { toggling: true })
    }
    case TOGGLE_DONE: {
      return Object.assign({}, state, { toggling: false })
    }
    default: return state
  }
}
