import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Loading from 'components/shared/loading/Loading'
import biActions from 'actions/businessInteligence'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import revoluchargeLogo from '/../resources/img/revoluchargelogo.png'
import revolutilityLogo from '/../resources/img/revolutilitylogo.png'
import CurrencyFormat from '../shared/CurrencyFormat'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  paper: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.text.secondary,
    flexGrow: 1
  },
  imgContainer: {
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  img: {
    maxWidth: '100%',
    width: '300px'
  },
  item: {
    display: 'flex'
  }
})

class Dashboard extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentDidMount () {
    this.dispatch(biActions.fetch())
  }

  render () {
    let chargeBi, utilityBi
    if (this.props.bi.data) {
      chargeBi = this.props.bi.data.revolucharge
      utilityBi = this.props.bi.data.revolutility
    }
    return (
      <NamespacesConsumer ns={['dashboard']}>
        {t => {
          const classes = this.props.classes
          return (
            <main>
              {this.props.bi.loading &&
              <Loading />
              }
              {chargeBi && utilityBi &&
              <div className={classes.root}>
                <div className={classes.imgContainer}>
                  <img className={classes.img} src={revoluchargeLogo} />
                </div>
                <Grid container spacing={24} className={classes.container}>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_ORDERS')}
                      </Typography>
                      <Typography variant='h6'>
                        {chargeBi.totalOrders}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_ORDER_AMOUNT')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={chargeBi.totalOrderAmount} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('UNIQUE_BUYERS')}
                      </Typography>
                      <Typography variant='h6'>
                        {chargeBi.uniqueBuyers}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_REWARDS')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={chargeBi.totalRewards} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDERS_LAST_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        {chargeBi.ordersDaily}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDER_AMOUNT_LAST_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={chargeBi.orderAmountDaily} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDERS_MONTHLY')}
                      </Typography>
                      <Typography variant='h6'>
                        {chargeBi.ordersMonthly}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDER_AMOUNT_MONTHLY')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={chargeBi.orderAmountMonthly} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('FAILED_DELIVERIES_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        {chargeBi.failedDailyDeliveries}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

                <div className={classes.imgContainer}>
                  <img className={classes.img} src={revolutilityLogo} />
                </div>
                <Grid container spacing={24} className={classes.container}>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_ORDERS')}
                      </Typography>
                      <Typography variant='h6'>
                        {utilityBi.totalOrders}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_ORDER_AMOUNT')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={utilityBi.totalOrderAmount} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('UNIQUE_BUYERS')}
                      </Typography>
                      <Typography variant='h6'>
                        {utilityBi.uniqueBuyers}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('TOTAL_REWARDS')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={utilityBi.totalRewards} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDERS_LAST_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        {utilityBi.ordersDaily}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDER_AMOUNT_LAST_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={utilityBi.orderAmountDaily} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDERS_MONTHLY')}
                      </Typography>
                      <Typography variant='h6'>
                        {utilityBi.ordersMonthly}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('ORDER_AMOUNT_MONTHLY')}
                      </Typography>
                      <Typography variant='h6'>
                        <CurrencyFormat value={utilityBi.orderAmountMonthly} currencyIso='₡CU' />
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.item} xs={12} sm={6} md={3}>
                    <Paper className={classes.paper}>
                      <Typography color='textSecondary' gutterBottom>
                        {t('FAILED_DELIVERIES_24HOURS')}
                      </Typography>
                      <Typography variant='h6'>
                        {utilityBi.failedDailyDeliveries}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

              </div>
              }
            </main>

          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(
  state => ({ bi: state.businessInteligence })
)(withRouter(withStyles(styles)(Dashboard)))
