import {
  FETCH,
  FETCH_DONE,
  SET_COMMISSION,
  SET_COMMISSION_DONE
} from 'actions/revolutility/products'

export default (
  state = {
    data: {},
    loading: false,
    settingCommission: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign(
        {},
        state,
        { loading: true, data: { ...state.data, limit: action.payload.limit } }
      )
    }
    case FETCH_DONE: {
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            products: action.payload.products,
            count: action.payload.count,
            page: action.payload.page,
            limit: action.payload.limit,
            name: action.payload.name
          }
        })
    }
    case SET_COMMISSION: {
      return Object.assign(
        {},
        state,
        { settingCommission: true }
      )
    }
    case SET_COMMISSION_DONE: {
      return Object.assign(
        {},
        state,
        { settingCommission: false }
      )
    }
    default: return state
  }
}
