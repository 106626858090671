import { call, put, takeLatest } from 'redux-saga/effects'
import operatorsActions, { FETCH, UPDATE } from 'actions/revolucharge/operators'

export default ({ api }) => {
  function * getOperators ({ payload }) {
    try {
      const response = yield call(api.revolucharge.getOperators, payload)
      yield put(operatorsActions.fetchDone({
        count: response.count,
        operators: response.operators,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(operatorsActions.fetchDone({
        count: 0,
        operators: [],
        page: payload.offset / payload.limit,
        limit: payload.limit,
        searchterm: payload.searchterm
      }))
    }
  }

  function * update ({ payload }) {
    try {
      yield call(api.revolucharge.updateOperator, payload)
    } catch (e) {
      console.log(e)
    } finally {
      yield put(operatorsActions.updateDone())
      yield put(operatorsActions.fetch({
        limit: payload.limit,
        offset: payload.offset,
        searchterm: payload.searchterm
      }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getOperators)
    yield takeLatest(UPDATE, update)
  }
}
