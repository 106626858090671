import React from 'react'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import ordersActions from 'actions/revolutility/orders'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'
import Chip from '@material-ui/core/Chip'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'

const initialRowsPerPage = 15

class Orders extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
  }

  componentDidMount () {
    this.dispatch(ordersActions.fetch({ limit: initialRowsPerPage }))
  }

  render () {
    return (
      <NamespacesConsumer ns={['orders']}>
        {t => {
          const columns = [
            {
              name: t('ID')
            },
            {
              name: t('NAME')
            },
            {
              name: t('PHONE')
            },
            {
              name: t('EMAIL')
            },
            {
              name: t('STATUS'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <Chip label={t(!value ? 'SUCCESS' : 'REFUNDED')} color={!value ? 'secondary' : 'primary'} />
                  )
                }
              }
            },
            {
              name: t('AMOUNT'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value} currencyIso='₡CU' />
                )
              }
            },
            {
              name: t('CREATED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateTimeFormat value={new Date(value)} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                  <IconButton onClick={() => {
                    this.history.push(`/revolutility/order/${value}`)
                  }}>
                    <LaunchIcon />
                  </IconButton>
                )
              }
            }
          ]
          let data = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.orders.data.orders) {
            count = this.props.orders.data.count
            page = this.props.orders.data.page
            rowsPerPage = this.props.orders.data.limit
            data = this.props.orders.data.orders.map(o => ([
              o.order.id,
              `${o.revolupayOrder.name}`,
              o.revolupayOrder.phone,
              o.revolupayOrder.email,
              o.hasRefunds,
              o.order.amount,
              o.order.createdAt,
              o.order.id
            ]))
          }
          const options = {
            serverSide: true,
            count,
            page,
            responsive: 'scroll',
            filter: false,
            download: false,
            selectableRows: false,
            sort: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.searchterm = searchText
                }
                this.dispatch(ordersActions.fetch(params))
              }
            }
          }
          return (
            <main>
              {this.props.orders.loading && !this.props.orders.data.orders &&
                <Loading />
              }
              {this.props.orders.data.orders &&
                <MUIDataTable data={data} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ orders: state.revolutilityOrders }))(withRouter(Orders))
