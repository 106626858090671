import React from 'react'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'
import productsActions from 'actions/revolutility/products'
import bestsellerActions from 'actions/revolutility/bestsellers'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import GavelIcon from '@material-ui/icons/Gavel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import StarIcon from '@material-ui/icons/Star'
import UpdateIcon from '@material-ui/icons/SystemUpdate'
import Tooltip from '@material-ui/core/Tooltip'

const initialRowsPerPage = 15

const styles = theme => ({
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionButtons: {
    display: 'flex'
  }

})

class Products extends React.Component {
  constructor ({ dispatch, history }) {
    super()
    this.dispatch = dispatch
    this.history = history
    this.state = {
      addBestsellerAlert: false,
      syncAlert: false,
      setCommission: false,
      commissionForm: { fixedAmount: '', percentage: '' }
    }
  }

  componentDidMount () {
    this.dispatch(productsActions.fetch({ limit: initialRowsPerPage }))
  }

  handleModalClose = () => {
    this.setState({
      addBestsellerAlert: false,
      syncAlert: false,
      setCommission: false,
      currentProduct: null,
      commissionForm: { fixedAmount: '', percentage: '' }
    })
  }

  showSyncAlert = () => {
    this.setState({
      syncAlert: true
    })
  }

  sync = () => {
    this.setState({
      syncAlert: false
    })
    this.dispatch(productsActions.sync())
  }

  addBestseller = () => {
    this.setState({
      addBestsellerAlert: false
    })
    this.dispatch(bestsellerActions.add({
      bestseller: {
        name: this.state.currentProduct.product.name,
        icon: this.state.currentProduct.product.logo
      }
    }))
  }

  updateCommissionFormValue = (e) => {
    this.setState({
      commissionForm: { ...this.state.commissionForm, [e.target.id]: parseFloat(e.target.value) }
    })
  }

  setCommission = () => {
    this.dispatch(productsActions.setCommission({
      reference: this.state.currentProduct.product.reference,
      providerId: this.state.currentProduct.provider.id,
      fixedAmount: this.state.commissionForm.fixedAmount ? this.state.commissionForm.fixedAmount : 0,
      percentage: this.state.commissionForm.percentage ? this.state.commissionForm.percentage : 0,
      limit: this.props.products.data.limit,
      offset: this.props.products.data.limit * this.props.products.data.page,
      name: this.props.products.data.name
    }))
    this.setState({
      setCommission: false
    })
  }

  render () {
    return (
      <NamespacesConsumer ns={['products']}>
        {t => {
          const columns = [
            {
              name: t('PROVIDER')
            },
            {
              name: t('COUNTRY')
            },
            {
              name: t('REFERENCE')
            },
            {
              name: t('NAME')
            },
            {
              name: t('MIN_SEND_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MAX_SEND_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MIN_RECEIVE_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('MAX_RECEIVE_VALUE'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('COMMISSION'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value.fixedAmount && value.percentage) {
                    return (
                      <React.Fragment>
                        <CurrencyFormat value={value.fixedAmount} currencyIso={value.currency} /> +
                        <CurrencyFormat value={value.percentage} currencyIso={'%'} />
                      </React.Fragment>
                    )
                  } else if (value.fixedAmount) {
                    return (
                      <CurrencyFormat value={value.fixedAmount} currencyIso={value.currency} />
                    )
                  } else if (value.percentage) {
                    return (
                      <CurrencyFormat value={value.percentage} currencyIso={'%'} />
                    )
                  } else {
                    return (
                      <CurrencyFormat value={value.defaultPercentage} currencyIso={'%'} />
                    )
                  }
                }
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                  <div className={classes.actionButtons}>
                    <Tooltip title={t('SET_COMMISSION')}>
                      <IconButton onClick={() => {
                        this.setState({
                          setCommission: true,
                          currentProduct: value,
                          commissionForm: {
                            fixedAmount: value.commission.fixedAmount ? value.commission.fixedAmount : '',
                            percentage: value.commission.percentage ? value.commission.percentage : ''
                          }
                        })
                      }}>
                        <GavelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('ADD_BESTSELLER')}>
                      <IconButton onClick={() => {
                        this.setState({
                          addBestsellerAlert: true,
                          currentProduct: value
                        })
                      }}>
                        <StarIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )
              }
            }
          ]
          let data = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.products.data.products) {
            count = this.props.products.data.count
            page = this.props.products.data.page
            rowsPerPage = this.props.products.data.limit
            data = this.props.products.data.products.map(p => ([
              p.provider.name,
              (p.countries.length > 1 ? t('AVAILAIBLBE_IN_SEVERAL_COUNTRIES') : p.countries[0].name),
              p.product.reference,
              p.product.name,
              { amount: p.product.minimumSendAmount, currency: p.sendCurrency.iso },
              { amount: p.product.maximumSendAmount, currency: p.sendCurrency.iso },
              { amount: p.product.minimumReceiveAmount, currency: p.receiveCurrency.iso },
              { amount: p.product.maximumReceiveAmount, currency: p.receiveCurrency.iso },
              {
                percentage: p.commission ? p.commission.percentage : 0,
                fixedAmount: p.commission ? p.commission.fixedAmount : 0,
                currency: p.sendCurrency.iso
              },
              p
            ]))
          }
          const options = {
            serverSide: true,
            count,
            page,
            responsive: 'scroll',
            filter: false,
            selectableRows: false,
            download: false,
            sort: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.name = searchText
                }
                this.dispatch(productsActions.fetch(params))
              }
            }
          }
          const classes = this.props.classes
          return (
            <main>
              <Dialog
                open={this.state.setCommission || this.props.products.settingCommission}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('SET_COMMISSION_TITLE')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('SET_COMMMISSION_DESCRIPTION')}
                  </DialogContentText>
                  <TextField
                    autoFocus
                    value={this.state.commissionForm.fixedAmount}
                    onChange={this.updateCommissionFormValue}
                    margin='dense'
                    id='fixedAmount'
                    label={t('FIXED_VALUE')}
                    fullWidth
                    type='number'
                  />
                  <TextField
                    value={this.state.commissionForm.percentage}
                    onChange={this.updateCommissionFormValue}
                    margin='dense'
                    id='percentage'
                    label={t('PERCENTAGE')}
                    fullWidth
                    type='number'
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} disabled={this.props.products.settingCommission}>
                    {t('CANCEL')}
                  </Button>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      onClick={this.setCommission}
                      disabled={this.props.products.settingCommission}
                      color='primary'
                      autoFocus>
                      {t('APPLY')}
                    </Button>
                    {this.props.products.settingCommission &&
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    }
                  </div>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.syncAlert}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('CONFIRMATION_REQUIRED')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('SYNC_ALERT_DESCRIPTION')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose}>
                    {t('CANCEL')}
                  </Button>
                  <Button
                    onClick={this.sync}
                    color='primary'
                    autoFocus>
                    {t('SYNC')}
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.addBestsellerAlert || this.props.bestsellers.loading}
                onClose={this.handleModalClose}
              >
                <DialogTitle>{t('CONFIRMATION_REQUIRED')}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('ADD_BESTSELLER_ALERT_DESCRIPTION')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose}>
                    {t('CANCEL')}
                  </Button>
                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      onClick={this.addBestseller}
                      disabled={this.props.bestsellers.loading}
                      color='primary'
                      autoFocus>
                      {t('CONFIRM')}
                    </Button>
                    {this.props.bestsellers.loading &&
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    }
                  </div>
                </DialogActions>
              </Dialog>
              <div className={classes.actionBar}>
                <Tooltip title={t('RESYNC_PRODUCTS')}>
                  <IconButton color='primary' onClick={() => {
                    this.showSyncAlert()
                  }}>
                    <UpdateIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {this.props.products.loading && !this.props.products.data.products &&
                <Loading />
              }
              {this.props.products.data.products &&
                <MUIDataTable data={data} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  products: state.revolutilityProducts,
  bestsellers: state.revolutilityBestsellers
}))(withRouter(withStyles(styles)(Products)))
