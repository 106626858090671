export const LOGIN_REQUEST = '@/admin/request_login'
export const LOGIN_CHECK = '@/admin/check_login_status'
export const LOGIN_CHECKED = '@/admin/check_login_status_done'
export const LOGIN_ERROR = '@/admin/login_error'
export const LOGIN_SUCCESS = '@/admin/login_success'
export const LOGIN_ERRORS = {
  ABORTED: 'ABORTED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED'
}
export const LOGOUT = '@/admin/request_logout'

export default {
  check: () => ({
    type: LOGIN_CHECK
  }),
  checkDone: () => ({
    type: LOGIN_CHECKED
  }),
  login: () => ({
    type: LOGIN_REQUEST
  }),
  logout: () => ({
    type: LOGOUT
  }),
  loginSuccess: ({ user }) => ({
    type: LOGIN_SUCCESS,
    payload: { user }
  }),
  loginError: ({ error }) => ({
    type: LOGIN_ERROR,
    payload: { error }
  })
}
