import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor () {
    super()
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render () {
    if (this.state.errorInfo) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <button onClick={() => {
            this.setState({ error: null, errorInfo: null })
          }}>
            Ok
          </button>
          {<details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>}
        </div>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
