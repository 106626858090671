import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import Loading from 'components/shared/loading/Loading'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import DateTimeFormat from 'components/shared/DateTimeFormat'
import CurrencyFormat from 'components/shared/CurrencyFormat'
import { withRouter } from 'react-router-dom'
import { withApi } from 'services/apiContext'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import LaunchIcon from '@material-ui/icons/Assignment'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import ObjectDebug from 'components/shared/ObjectDebug'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  container: {
    marginBottom: theme.spacing.unit

  },
  notFound: {
    display: 'flex',
    flexGrow: 1,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }

})

const Transition = (props) => {
  return (<Slide direction='up' {...props} />)
}

class BatchOrder extends React.Component {
  constructor ({ history, match, api }) {
    super()
    this.api = api
    this.batchOrderId = match.params.id
    this.history = history
    this.state = {
      loading: true,
      transferDetails: null,
      data: null,
      downloadUrl: null,
      performingAction: false
    }
    this.fetchData = this.fetchData.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.getStatusChip = this.getStatusChip.bind(this)
    this.markAsFinished = this.markAsFinished.bind(this)
    this.enqueue = this.enqueue.bind(this)
  }

  componentDidMount () {
    this.fetchData()
    this.interval = setInterval(this.fetchData, 15000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  markAsFinished () {
    this.setState({ performingAction: true })
    const run = async () => {
      try {
        await this.api.revolucharge.markBatchOrderAsFinished({ batchOrderId: this.batchOrderId })
        await this.fetchData()
      } catch (e) {
        console.error(e)
      }
      this.setState({ performingAction: false })
    }
    run()
  }

  fetchData () {
    const run = async () => {
      let batchOrder = null
      let downloadUrl = null
      try {
        batchOrder = await this.api.revolucharge.getBatchOrder({
          batchOrderId: this.batchOrderId
        })
        downloadUrl = (await this.api.revolucharge.getBatchOrderResultsDownloadUrl({ batchOrderId: this.batchOrderId })).url
      } catch (e) {
        console.log(e)
      }
      this.setState({ loading: false, data: batchOrder, downloadUrl })
    }
    return run()
  }

  enqueue () {
    this.setState({ performingAction: true })
    const run = async () => {
      try {
        await this.api.revolucharge.enqueueBatchOrders({ batchOrderId: this.batchOrderId })
        await this.fetchData()
      } catch (e) {
        console.error(e)
      }
      this.setState({ performingAction: false })
    }
    run()
  }

  handleModalClose () {
    this.setState({ transferDetails: null })
  }

  getStatusChip (t, value) {
    value = value.toUpperCase()
    let color = 'default'
    if (value === 'FINISHED') {
      color = 'secondary'
    }
    if (value === 'PROCESSED' || value === 'ENQUEUED') {
      color = 'primary'
    }
    return (
      <Chip label={t(value)} color={color} />
    )
  }

  render () {
    return (
      <NamespacesConsumer ns={['batchOrders']}>
        {t => {
          const columns = [
            {
              name: t('ID')
            },
            {
              name: t('STATUS'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  let color = 'primary'
                  if (value === 'SUCCESS') {
                    color = 'secondary'
                  }
                  if (value === 'PENDING') {
                    color = 'default'
                  }
                  return (
                    <Chip label={t(value)} color={color} />
                  )
                }
              }
            },
            {
              name: t('REFERENCE')
            },
            {
              name: t('BENEFICIARY')
            },
            {
              name: t('SEND_AMOUNT'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('RECEIVE_AMOUNT'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <CurrencyFormat value={value.amount} currencyIso={value.currency} />
                )
              }
            },
            {
              name: t('RESPONSE'),
              options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (<IconButton disabled={value === null} onClick={() => {
                    this.setState({ transferDetails: value })
                  }}>
                    <LaunchIcon />
                  </IconButton>
                  )
                }
              }
            }
          ]
          const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            selectableRows: false,
            rowsPerPage: 100,
            rowsPerPageOptions: [15, 50, 100]
          }
          const classes = this.props.classes
          let pendingTransfers = 0
          let successfulTransfers = 0
          let failedTransfers = 0
          let processingTransfers = 0
          let transfers = []
          if (this.state.data) {
            pendingTransfers = this.state.data.transfers.filter(t => t.status === 'PENDING').length
            successfulTransfers = this.state.data.transfers.filter(t => t.status === 'SUCCESS').length
            failedTransfers = this.state.data.transfers.filter(t => t.status === 'ERROR').length
            processingTransfers = this.state.data.transfers.filter(t => t.status === 'PROCESSING').length
            transfers = this.state.data.transfers.map(t => ([
              t.id,
              t.status,
              t.reference ? t.reference : '-',
              t.beneficiary,
              { amount: t.sendValue, currency: this.state.data.batchOrder.sendCurrency },
              { amount: t.receiveValue, currency: this.state.data.batchOrder.receiveCurrency },
              t.response
            ]))
          }
          return (
            <main>
              <Dialog
                open={this.state.transferDetails !== null}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleModalClose}
              >
                <DialogTitle>
                  {t('TRANSFER_DETAILS')}
                </DialogTitle>
                <DialogContent>
                  <ObjectDebug object={this.state.transferDetails} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleModalClose} color='primary'> {t('CLOSE')} </Button>
                </DialogActions>
              </Dialog>
              <div>
                <IconButton color='primary' onClick={() => {
                  this.history.goBack()
                }}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              {this.state.loading &&
                <Loading />
              }
              {!this.state.loading && this.state.data === null &&
                <div className={classes.notFound}>
                  <Typography variant='h2'>
                    {t('BATCH_ORDER_NOT_FOUND_ERROR')}
                  </Typography>
                </div>
              }
              {!this.state.loading && this.state.data &&
                <div className={classes.root}>
                  <Grid container spacing={24} className={classes.container}>
                    <Grid item xs className={classes.flex}>
                      <Card className={classes.grow}>
                        <CardContent>
                          <Typography variant='h6' gutterBottom>
                            {t('BATCH_ORDER')}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('ID')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {this.state.data.batchOrder.id}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('STATUS')}
                          </Typography>
                          {this.getStatusChip(t, this.state.data.batchOrder.status)}
                          <Typography variant='subtitle1'>
                            {t('NAME')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {this.state.data.batchOrder.name}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('PRODUCT')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {`${this.state.data.batchOrder.providerName} - ${this.state.data.batchOrder.countryName} - ${this.state.data.batchOrder.operatorName} - ${this.state.data.batchOrder.productName}`}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('ROWS')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {this.state.data.batchOrder.rows}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('SEND_AMOUNT')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            <CurrencyFormat value={this.state.data.batchOrder.sendAmount} currencyIso={this.state.data.batchOrder.sendCurrency} />
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('RECEIVE_AMOUNT')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            <CurrencyFormat value={this.state.data.batchOrder.receiveAmount} currencyIso={this.state.data.batchOrder.receiveCurrency} />
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('CREATED')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            <DateTimeFormat value={new Date(this.state.data.batchOrder.createdAt)} />
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('UPDATED')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            <DateTimeFormat value={new Date(this.state.data.batchOrder.updatedAt)} />
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('PENDING_TRANSFERS')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {pendingTransfers}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('PROCESSING_TRANSFERS')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {processingTransfers}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('SUCCESSFUL_TRANSFERS')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {successfulTransfers}
                          </Typography>
                          <Typography variant='subtitle1'>
                            {t('FAILED_TRANSFERS')}
                          </Typography>
                          <Typography variant='body1' color='textSecondary'>
                            {failedTransfers}
                          </Typography>
                        </CardContent>
                        <CardActions className={classes.buttonContainer}>
                          {this.state.data.batchOrder.status.toUpperCase() === 'PROCESSED' &&
                            <div className={classes.loadingButtonWrapper}>
                              <Button
                                disabled={this.state.performingAction}
                                onClick={this.enqueue}
                              >
                                {t('ENQUEUE_TRANSFERS')}                                                         </Button>
                              {this.state.performingAction &&
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              }
                            </div>

                          }
                          {
                            (this.state.data.batchOrder.status.toUpperCase() === 'FINISHED' ||
                              this.state.data.batchOrder.status.toUpperCase() === 'ENQUEUED') &&
                              <Button
                                disabled={this.state.downloadUrl === null}
                                target='_blank'
                                href={this.state.downloadUrl}
                              >
                                {t('DOWNLOAD_RESULTS')}
                              </Button>
                          }
                          {
                            this.state.data.batchOrder.status.toUpperCase() === 'ENQUEUED' &&
                            <div className={classes.loadingButtonWrapper}>
                              <Button
                                disabled={this.state.performingAction}
                                onClick={this.markAsFinished}
                              >
                                {t('MARK_AS_FINISHED')}
                              </Button>
                              {this.state.performingAction &&
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              }
                            </div>

                          }
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                  <Typography variant='h6' gutterBottom>
                    {t('TRANSFERS')}
                  </Typography>
                  <MUIDataTable data={transfers} columns={columns} options={options} />
                </div>
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default withRouter(withApi(withStyles(styles)(BatchOrder)))
