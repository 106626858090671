export const FETCH = '@revolutility/products/fetch'
export const FETCH_DONE = '@revolutility/products/fetch_done'
export const SET_COMMISSION = '@revolutility/products/set_commission'
export const SET_COMMISSION_DONE = '@revolutility/products/set_commission_done'
export const SYNC = '@revolutility/products/sync'

export default {
  setCommission: ({ fixedAmount, percentage, reference, providerId, limit, offset, name }) => ({
    type: SET_COMMISSION,
    payload: {
      fixedAmount, percentage, reference, providerId, limit, offset, name
    }
  }),
  setCommissionDone: () => ({
    type: SET_COMMISSION_DONE
  }),
  fetch: ({ limit = 10, offset = 0, name = null } = {}) => ({
    type: FETCH,
    payload: {
      limit, offset, name
    }
  }),
  putProducts: ({ products, count, page, name, limit }) => ({
    type: FETCH_DONE,
    payload: { products, count, page, limit, name }
  }),
  sync: () => ({
    type: SYNC
  })
}
