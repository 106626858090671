export const ENQUEUE = '@/notifications/enqueue'
export const REMOVE = '@/notifications/remove'
export default {
  enqueue: notification => ({
    type: ENQUEUE,
    payload: {
      notification: { key: new Date().getTime() + Math.random(), ...notification }
    }
  }),
  remove: key => ({
    type: REMOVE,
    payload: { key }
  })
}
