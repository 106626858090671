import {
  LOGIN_REQUEST,
  LOGIN_CHECK,
  LOGIN_CHECKED,
  LOGIN_ERROR,
  LOGIN_SUCCESS
} from 'actions/auth'

export default (
  state = {
    user: null,
    loading: false,
    checked: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return Object.assign({}, state, { loading: true, error: null })
    }
    case LOGIN_ERROR: {
      return Object.assign({}, state, { loading: false, checked: true, user: null, error: action.payload.error })
    }
    case LOGIN_CHECK: {
      return Object.assign({}, state, { loading: true, error: null })
    }
    case LOGIN_CHECKED: {
      return Object.assign({}, state, { loading: false, checked: true, error: null })
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, { user: action.payload.user, checked: true, loading: false })
    }
    default: return state
  }
}
