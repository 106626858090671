export const FETCH = '@revolucharge/products/fetch'
export const FETCH_DONE = '@revolucharge/products/fetch_done'

export default {
  fetch: ({ limit = 10, offset = 0, searchterm = null } = {}) => ({
    type: FETCH,
    payload: {
      limit, offset, searchterm
    }
  }),
  putProducts: ({ products, count, page, limit }) => ({
    type: FETCH_DONE,
    payload: { products, count, page, limit }
  })
}
