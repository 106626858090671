import React from 'react'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import Loading from 'components/shared/loading/Loading'
import DescriptionIcon from '@material-ui/icons/Description'
import UnstarIcon from '@material-ui/icons/StarBorder'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import promotionActions from 'actions/revolucharge/promotions'
import DateFormat from 'components/shared/DateFormat'
import style from './Style'
import Tooltip from '@material-ui/core/Tooltip'

const Transition = (props) => {
  return (<Slide direction='up' {...props} />)
}

class Promotions extends React.Component {
  constructor ({ dispatch, i18n }) {
    super()
    this.state = { currentPromo: null }
    this.dispatch = dispatch
    this.i18n = i18n
  }

  componentDidMount () {
    this.dispatch(promotionActions.fetch())
  }

  handleModalClose = () => {
    this.setState({ currentPromo: null })
  }

  getLocalizedDingPromotionDescription = content => {
    const locale = this.i18n.language
    const filteredDescriptions = content.descriptions.filter((e) => e.languageCode === locale)
    if (filteredDescriptions.length > 0) {
      return filteredDescriptions[0]
    } else return content.descriptions[0]
  }

  render () {
    return (
      <NamespacesConsumer ns={['promotions']}>
        {t => {
          const columns = [
            t('ID'),
            t('PROVIDER'),
            t('COUNTRY'),
            t('OPERATOR'),
            t('HEADLINE'),
            {
              name: t('START'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateFormat value={new Date(value)} />
                )
              }
            },
            {
              name: t('END'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <DateFormat value={new Date(value)} />
                )
              }

            },
            {
              name: t('HIGHLIGHTED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Chip label={value ? t('YES') : t('NO')} color={value ? 'secondary' : 'primary'} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                sort: false,
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const promo = value
                  const highlightAction = () => {
                    this.dispatch(promotionActions.highlight({
                      id: promo.id, highlight: !promo.highlighted
                    }))
                  }
                  const detailAction = () => {
                    this.setState({ currentPromo: promo })
                  }
                  let higlightButton
                  if (promo.highlighted) {
                    higlightButton = (
                      <IconButton
                        disabled={this.props.promotions.loading}
                        onClick={highlightAction} >
                        <UnstarIcon />
                      </IconButton>
                    )
                  } else {
                    higlightButton = (
                      <IconButton
                        disabled={this.props.promotions.loading}
                        onClick={highlightAction}>
                        <StarIcon />
                      </IconButton>
                    )
                  }
                  return (
                    <div className={style.actionButtons}>
                      <Tooltip title={t('HIGHLIGHT_BUTTON_TOOLTIP')}>
                        {higlightButton}
                      </Tooltip>
                      <Tooltip title={t('DESCRIPTION_BUTTON_TOOLTIP')}>
                        <IconButton onClick={detailAction}>
                          <DescriptionIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )
                }
              }
            }
          ]
          const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            selectableRows: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 50, 100]
          }
          let promos = []
          if (this.props.promotions.data.promotions) {
            promos = this.props.promotions.data.promotions.map(promo => {
              const content = JSON.parse(promo.content)
              if (promo.provider.impl === 'DING') {
                const description = this.getLocalizedDingPromotionDescription(content)
                return [
                  promo.id,
                  promo.provider.name,
                  promo.operator.country.name,
                  promo.operator.name,
                  description.headline,
                  content.startUtc,
                  content.endUtc,
                  promo.highlighted,
                  promo
                ]
              } else {
                return [
                  promo.id,
                  promo.provider.name,
                  promo.operator.country.name,
                  promo.operator.name,
                  content.title,
                  content.start,
                  content.end,
                  promo.highlighted,
                  promo
                ]
              }
            })
          }
          const currentPromo = this.state.currentPromo
          let currentPromoDescription = ''
          let currentPromoHeadline = ''
          if (currentPromo) {
            if (currentPromo.provider.impl === 'DING') {
              const description = this.getLocalizedDingPromotionDescription(
                JSON.parse(currentPromo.content)
              )
              currentPromoDescription = description.termsAndConditions
              currentPromoHeadline = description.headline
            } else {
              const description = JSON.parse(currentPromo.content)
              currentPromoDescription = description.conditions
              currentPromoHeadline = description.title
            }
          }
          return (
            <main>
              <Dialog
                open={this.state.currentPromo !== null}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleModalClose}
              >
                <DialogTitle>
                  {currentPromoHeadline}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {currentPromoDescription}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleModalClose}
                    color='primary'>
                    {t('CLOSE')}
                  </Button>
                </DialogActions>
              </Dialog>
              {
                promos.length === 0 && this.props.promotions.loading &&
                <Loading />
              }
              {
                promos.length > 0 &&
                <MUIDataTable data={promos} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer >
    )
  }
}

export default connect(state => ({
  promotions: state.promotions
}))(withI18n()(Promotions))
