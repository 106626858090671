import { call, put, takeLatest } from 'redux-saga/effects'
import bestsellerActions, { FETCH, ADD, DELETE } from 'actions/revolutility/bestsellers'
import notificationsActions from 'actions/notifications'

export default ({ api, i18n }) => {
  function * getBestsellers () {
    try {
      const bestsellers = yield call(api.revolutility.getBestsellers)
      yield put(bestsellerActions.fetchDone({
        bestsellers
      }))
    } catch (e) {
      console.log(e)
      yield put(bestsellerActions.fetchDone({ bestsellers: [] }))
    }
  }

  function * addBestseller ({ payload }) {
    try {
      yield call(api.revolutility.addBestseller, payload)
      yield put(bestsellerActions.fetch())
      yield put(notificationsActions.enqueue({
        message: i18n.t('ADD_BESTSELLER_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('ADD_BESTSELLER_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(bestsellerActions.addDone())
    }
  }

  function * deleteBestseller ({ payload }) {
    try {
      yield call(api.revolutility.deleteBestseller, payload)
      yield put(bestsellerActions.fetch())
      yield put(notificationsActions.enqueue({
        message: i18n.t('DELETE_BESTSELLER_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('DELETE_BESTSELLER_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(bestsellerActions.deleteDone())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getBestsellers)
    yield takeLatest(ADD, addBestseller)
    yield takeLatest(DELETE, deleteBestseller)
  }
}
