export const FETCH = '@revolucharge/operators/fetch'
export const FETCH_DONE = '@revolucharge/operators/fetch_done'
export const UPDATE = '@revolucharge/operators/update'
export const UPDATE_DONE = '@revolucharge/operators/update_done'

export default {
  fetch: ({ limit = 10, offset = 0, searchterm = null }) => ({
    type: FETCH,
    payload: { limit, offset, searchterm }
  }),
  fetchDone: ({ operators, count, page, limit }) => ({
    type: FETCH_DONE,
    payload: { operators, count, page, limit }
  }),
  update: ({ id, name, logo, careNumber, disabled, limit, offset, searchterm }) => ({
    type: UPDATE,
    payload: {
      id, name, logo, careNumber, disabled, limit, offset, searchterm
    }
  }),
  updateDone: () => ({
    type: UPDATE_DONE,
    payload: {}

  })
}
