export const FETCH = '@revolu/bi/fetch'
export const FETCH_DONE = '@revolu/bi/fetch_done'

export default {
  fetch: () => ({
    type: FETCH,
    payload: {}
  }),
  fetchDone: (bi) => ({
    type: FETCH_DONE,
    payload: bi
  })
}
