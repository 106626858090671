import React from 'react'
import style from './Style.scss'
import logo from '/../resources/img/logo.png'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import loginActions from 'actions/auth'
import CircularProgress from '@material-ui/core/CircularProgress'
import { NamespacesConsumer } from 'react-i18next'

const Login = ({ dispatch, auth }) => {
  if (auth.user !== null) {
    return (
      <Redirect to='/' />
    )
  }

  const requestLogin = () => {
    dispatch(loginActions.login())
  }

  return (
    <NamespacesConsumer ns={['login']}>
      {t => (
        <main className={style.main}>
          <div className={style.jumbotron}>
            <img src={logo} />
            <div className={style.wrapper}>
              <Button
                onClick={requestLogin}
                disabled={auth.loading}
                variant='contained'
                size='large'
                color='primary'>
                {t('LOGIN')}
              </Button>
              {auth.loading &&
                <CircularProgress color='secondary' />
              }
              {!auth.loading && auth.error &&
                <Typography color='error' variant='subtitle2'>
                  {t(auth.error)}
                </Typography>
              }
            </div>
          </div>
        </main>
      )}
    </NamespacesConsumer>
  )
}
export default connect(state => ({ auth: state.auth }))(Login)
