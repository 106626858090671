export const FETCH = '@revolutility/bestsellers/fetch'
export const FETCH_DONE = '@revolutility/bestsellers/fetch_done'
export const ADD = '@revolutility/bestsellers/add'
export const ADD_DONE = '@revolutility/bestsellers/add_done'
export const DELETE = '@revolutility/bestsellers/delete'
export const DELETE_DONE = '@revolutility/bestsellers/delete_done'

export default {
  fetch: () => ({
    type: FETCH,
    payload: {}
  }),
  fetchDone: (bestsellers) => ({
    type: FETCH_DONE,
    payload: { bestsellers }
  }),
  add: ({ bestseller }) => ({
    type: ADD,
    payload: { bestseller }
  }),
  addDone: () => ({
    type: ADD_DONE,
    payload: {}
  }),
  delete: ({ bestsellerId }) => ({
    type: DELETE,
    payload: { bestsellerId }
  }),
  deleteDone: () => ({
    type: DELETE_DONE,
    payload: {}
  })
}
