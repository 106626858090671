import { call, put, takeLatest } from 'redux-saga/effects'
import cysendOperatorsActions, { FETCH, UPDATE } from 'actions/revolucharge/cysendOperators'
import notificationsActions from 'actions/notifications'

export default ({ api, i18n }) => {
  function * getCysendOperators ({ payload }) {
    try {
      const operators = yield call(api.revolucharge.getCysendOperators, payload)
      yield put(cysendOperatorsActions.putOperators({
        count: operators.count,
        operators: operators.data,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(cysendOperatorsActions.putOperators({ operators: [] }))
    }
  }

  function * update ({ payload }) {
    try {
      yield call(api.revolucharge.updateCysendOperator, payload)
      yield put(cysendOperatorsActions.fetch(payload))
      yield put(notificationsActions.enqueue({
        message: i18n.t('UPDATE_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('UPDATE_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(cysendOperatorsActions.updateDone())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getCysendOperators)
    yield takeLatest(UPDATE, update)
  }
}
