import { call, put, takeLatest } from 'redux-saga/effects'
import promotionActions, { FETCH, HIGHLIGHT } from 'actions/revolucharge/promotions'

export default ({ api }) => {
  function * getPromos () {
    try {
      const promos = yield call(api.revolucharge.getPromotions)
      yield put(promotionActions.putPromotions({ promotions: promos }))
    } catch (e) {
      console.log(e)
      yield put(promotionActions.putPromotions({ promotions: [] }))
    }
  }

  function * highlight ({ payload }) {
    try {
      yield call(api.revolucharge.highlightPromotion, payload)
    } catch (e) {
      console.log(e)
    } finally {
      yield put(promotionActions.fetch())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getPromos)
    yield takeLatest(HIGHLIGHT, highlight)
  }
}
