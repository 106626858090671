import { call, put, take, race, delay } from 'redux-saga/effects'
import loginActions, { LOGIN_REQUEST, LOGIN_CHECK, LOGOUT, LOGIN_ERRORS } from 'actions/auth'

export default ({ loginWindow, tokens }) => {
  return function * watch () {
    while (true) {
      const loginRace = yield race({
        request: take(LOGIN_REQUEST),
        check: take(LOGIN_CHECK)
      })
      let aborted = false
      if (loginRace.request) {
        try {
          const auth = yield call(loginWindow)
          yield call(tokens.put, auth)
        } catch (e) {
          aborted = true
          yield put(loginActions.loginError({ error: LOGIN_ERRORS.ABORTED }))
        }
      }
      try {
        const auth = yield call(tokens.get)
        const token = yield call(tokens.decode, auth.accessToken)
        const expDelayInMs = (token.exp - (Date.now() / 1000)) * 1000
        const user = yield call(tokens.decode, auth.idToken)
        yield put(loginActions.loginSuccess({ user }))
        const logoutRace = yield race({
          expiredToken: delay(expDelayInMs),
          logout: take(LOGOUT)
        })
        yield call(tokens.remove)
        if (logoutRace.expiredToken) {
          yield put(loginActions.loginError({ error: LOGIN_ERRORS.TOKEN_EXPIRED }))
        }
      } catch (e) {
        if (loginRace.request && !aborted) {
          yield put(loginActions.loginError({ error: LOGIN_ERRORS.INVALID_TOKEN }))
        }
        if (loginRace.check) {
          yield put(loginActions.checkDone())
        }
        yield call(tokens.remove)
      }
    }
  }
}
