
import React from 'react'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { NamespacesConsumer } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { FormControl } from '@material-ui/core'
import { withApi } from 'services/apiContext'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  loadingMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    left: 2,
    fontSize: 16
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2
  },
  paper: {
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  }
})

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function LoadingMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.loadingMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

function Control (props) {
  return (
    <FormControl fullWidth error={props.selectProps.error} className={props.selectProps.classes.formControl}>
      <TextField
        fullWidth
        error={props.selectProps.error}
        InputProps={{
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
      <FormHelperText>{props.selectProps.helperText}</FormHelperText>
    </FormControl>
  )
}

function Option (props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue (props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  )
}

function ValueContainer (props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
  )
}

function Menu (props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  LoadingMessage
}

class SearchableSelect extends React.Component {
  constructor ({ api }) {
    super()
    this.api = api
    this.state = {
      loading: false,
      products: []
    }
  }

  componentDidMount () {
    this.fetchSuggestions()
    this.fetchSuggestions = this.fetchSuggestions.bind(this)
  }

  fetchSuggestions = input => {
    const request = { limit: 100, offset: 0 }
    if (input) {
      request.searchterm = input
    }
    const run = async () => {
      let products = []
      try {
        products = (await this.api.revolucharge.getProducts(request)).data
      } catch (e) {
        console.error(e)
      }
      this.setState({ products, loading: false })
    }
    run()
  }

  render () {
    const { classes, theme } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    const suggestions = this.state.products.map(p => ({
      label: `${p.provider.name} - ${p.operator.name} - ${p.product.name}`,
      value: p.product.id
    }))

    return (
      <NamespacesConsumer ns={['batchOrders']}>
        {t => (
          <div className={classes.root}>
            <Select
              classes={classes}
              styles={selectStyles}
              options={suggestions}
              components={components}
              textFieldProps={{
                label: t('PRODUCT'),
                InputLabelProps: {
                  shrink: true
                }
              }}
              error={this.props.error}
              value={this.props.value}
              onInputChange={this.fetchSuggestions}
              onChange={this.props.onValue}
              helperText={t('PRODUCT_SELECT_HELPER_TEXT')}
              isLoading={this.state.loading}
              placeholder={t('PRODUCT_SELECT_PLACEHOLDER')}
              isClearable
            />
          </div>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withStyles(styles, { withTheme: true })(withApi(SearchableSelect))
