import { call, put, takeLatest } from 'redux-saga/effects'
import providerOperatorsActions, { FETCH } from 'actions/revolucharge/providerOperators'

export default ({ api }) => {
  function * getProviderOperators ({ payload }) {
    try {
      const providerOperators = yield call(api.revolucharge.getProviderOperators, payload)
      yield put(providerOperatorsActions.putProviderOperators({
        count: providerOperators.count,
        providerOperators: providerOperators.data,
        page: payload.offset / payload.limit,
        limit: payload.limit
      }))
    } catch (e) {
      console.log(e)
      yield put(providerOperatorsActions.putProviderOperators({ providerOperators: [] }))
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getProviderOperators)
  }
}
