import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import notificationsActions from 'actions/notifications'

class Notifier extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
    this.displayed = []
  }

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id]
  }

  shouldComponentUpdate ({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props
    let notExists = false
    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length
    }
    return notExists
  }

  componentDidUpdate () {
    const { notifications = [] } = this.props
    notifications.forEach(notification => {
      if (this.displayed.includes(notification.key)) return
      this.props.enqueueSnackbar(notification.message, notification.options)
      this.storeDisplayed(notification.key)
      this.dispatch(notificationsActions.remove({ key: notification.key }))
    })
  }
  render () {
    return null
  }
}

export default connect(
  state => ({ notifications: state.notifications.notifications })
)(withSnackbar(Notifier))
