import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import operatorsActions from 'actions/revolucharge/operators'
import Loading from 'components/shared/loading/Loading'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'

const initialRowsPerPage = 15

class Operators extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentDidMount () {
    this.dispatch(operatorsActions.fetch({ limit: initialRowsPerPage }))
  }

  toggle = (operator) => {
    this.dispatch(operatorsActions.update({
      id: operator.id,
      disabled: !operator.disabled,
      limit: this.props.operators.data.limit,
      offset: this.props.operators.data.limit * this.props.operators.data.page,
      searchterm: this.props.operators.data.searchterm
    }))
  }

  render () {
    return (
      <NamespacesConsumer ns={['operators']}>
        {t => {
          const columns = [
            { name: t('ID') },
            { name: t('NAME') },
            { name: t('LOGO') },
            { name: t('CARE_NUMBER') },
            {
              name: t('ENABLED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Chip label={value ? t('NO') : t('YES')} color={value ? 'primary' : 'secondary'} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                sort: false,
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const operator = value
                  let icon
                  if (!operator.disabled) {
                    icon = (<PauseCircleFilled />)
                  } else {
                    icon = (<PlayCircleFilled />)
                  }
                  return (
                    <IconButton
                      disabled={this.props.operators.updating}
                      onClick={() => { this.toggle(operator) }} >
                      {icon}
                    </IconButton>
                  )
                }
              }
            }
          ]
          let operators = []
          let count = 0
          let page = 0
          let rowsPerPage = initialRowsPerPage
          if (this.props.operators.data.operators) {
            count = this.props.operators.data.count
            page = this.props.operators.data.page
            rowsPerPage = this.props.operators.data.limit
            operators = this.props.operators.data.operators.map(operator => ([
              operator.id,
              operator.name,
              operator.logo,
              operator.careNumber,
              operator.disabled,
              operator
            ]))
          }

          const options = {
            serverSide: true,
            count,
            page,
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            sort: false,
            filter: false,
            selectableRows: false,
            rowsPerPage,
            rowsPerPageOptions: [15, 50, 100],
            onTableChange: (action, tableState) => {
              if ([
                'changeRowsPerPage',
                'search',
                'changePage'
              ].includes(action)) {
                const { page, rowsPerPage, searchText } = tableState
                const params = {
                  offset: page * rowsPerPage,
                  limit: rowsPerPage
                }
                if (searchText) {
                  params.searchterm = searchText
                }
                this.dispatch(operatorsActions.fetch(params))
              }
            }
          }

          return (
            <main>
              {
                !this.props.operators.data.operators && this.props.operators.loading &&
                <Loading />
              }
              {
                this.props.operators.data.operators &&
                <MUIDataTable data={operators} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ operators: state.operators }))(Operators)
