import { ENQUEUE, REMOVE } from 'actions/notifications'

export default (
  state = {
    notifications: []
  },
  action
) => {
  switch (action.type) {
    case ENQUEUE: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload.notification
          }
        ]
      }
    }
    case REMOVE: {
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload.key
        )
      }
    }
    default: return state
  }
}
