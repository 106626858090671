import {
  FETCH,
  FETCH_DONE,
  HIGHLIGHT
} from 'actions/revolucharge/promotions'

export default (
  state = {
    data: {},
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign({}, state, { loading: true })
    }
    case FETCH_DONE: {
      return Object.assign({}, state, { loading: false, data: { promotions: action.payload.promotions } })
    }
    case HIGHLIGHT: {
      return Object.assign({}, state, { loading: true })
    }
    default: return state
  }
}
