import { call, put, takeLatest } from 'redux-saga/effects'
import configActions, { FETCH, UPDATE } from 'actions/revolucharge/config'
import notificationsActions from 'actions/notifications'

export default ({ api, i18n }) => {
  function * getConfig () {
    try {
      const config = yield call(api.revolucharge.getConfig)
      yield put(configActions.putConfig({
        config
      }))
    } catch (e) {
      console.log(e)
      yield put(configActions.putConfig({ config: {} }))
    }
  }

  function * update ({ payload }) {
    try {
      console.log(payload)
      yield call(api.revolucharge.updateConfig, payload)
      yield put(configActions.fetch())
      yield put(notificationsActions.enqueue({
        message: i18n.t('UPDATE_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
      yield put(notificationsActions.enqueue({
        message: i18n.t('UPDATE_ERROR'),
        options: {
          variant: 'error'
        }
      }))
    } finally {
      yield put(configActions.updateDone())
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getConfig)
    yield takeLatest(UPDATE, update)
  }
}
