import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { connect } from 'react-redux'
import providersActions from 'actions/revolucharge/providers'
import Loading from 'components/shared/loading/Loading'
import MUIDataTable from 'mui-datatables'
import Chip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'
import Tooltip from '@material-ui/core/Tooltip'

class Providers extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentDidMount () {
    this.dispatch(providersActions.fetch())
  }

  toggle = (provider) => {
    this.dispatch(providersActions.toggle(
      { id: provider.id, disabled: !provider.disabled })
    )
  }

  render () {
    return (
      <NamespacesConsumer ns={['providers']}>
        {t => {
          const columns = [
            { name: t('ID') },
            { name: t('NAME') },
            { name: t('IMPL') },
            {
              name: t('ENABLED'),
              options: {
                customBodyRender: (value, tableMeta, updateValue) => (
                  <Chip label={value ? t('NO') : t('YES')} color={value ? 'primary' : 'secondary'} />
                )
              }
            },
            {
              name: t('ACTIONS'),
              options: {
                sort: false,
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const provider = JSON.parse(value)
                  let icon
                  if (!provider.disabled) {
                    icon = (<PauseCircleFilled />)
                  } else {
                    icon = (<PlayCircleFilled />)
                  }
                  return (
                    <Tooltip title={t('ENABLE_BUTTON_TOOLTIP')}>
                      <IconButton
                        disabled={this.props.providers.toggling}
                        onClick={() => { this.toggle(provider) }} >
                        {icon}
                      </IconButton>
                    </Tooltip>
                  )
                }
              }
            }
          ]
          const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            selectableRows: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 50, 100]
          }
          let providers = []
          if (this.props.providers.data.providers) {
            providers = this.props.providers.data.providers.map(provider => ([
              provider.id,
              provider.name,
              provider.impl,
              provider.disabled,
              JSON.stringify(provider)
            ]))
          }

          return (
            <main>
              {
                providers.length === 0 && this.props.providers.loading &&
                <Loading />
              }
              {
                providers.length > 0 &&
                <MUIDataTable data={providers} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ providers: state.providers }))(Providers)
