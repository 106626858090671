import React from 'react'
import { connect } from 'react-redux'
import MUIDataTable from 'mui-datatables'
import IconButton from '@material-ui/core/IconButton'
import Loading from 'components/shared/loading/Loading'
import DeleteIcon from '@material-ui/icons/Delete'
import { NamespacesConsumer, withI18n } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import bestsellerActions from 'actions/revolutility/bestsellers'
import Tooltip from '@material-ui/core/Tooltip'

const Transition = (props) => {
  return (<Slide direction='up' {...props} />)
}

class Bestsellers extends React.Component {
  constructor ({ dispatch, i18n }) {
    super()
    this.state = { currentBestseller: null }
    this.dispatch = dispatch
    this.i18n = i18n
  }

  componentDidMount () {
    this.dispatch(bestsellerActions.fetch())
  }

  handleModalClose = () => {
    this.setState({ currentBestseller: null })
  }

  delete = () => {
    this.dispatch(bestsellerActions.delete({ bestsellerId: this.state.currentBestseller.id }))
    this.setState({ currentBestseller: null })
  }

  render () {
    return (
      <NamespacesConsumer ns={['bestsellers']}>
        {t => {
          const columns = [
            t('ID'),
            t('NAME'),
            {
              name: t('ACTIONS'),
              options: {
                sort: false,
                filter: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const bestseller = value
                  return (
                    <Tooltip title={t('DELETE')}>
                      <IconButton
                        disabled={this.props.bestsellers.loading}
                        onClick={() => {
                          this.setState({ currentBestseller: bestseller })
                        }} >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }
              }
            }
          ]
          const options = {
            filterType: 'multiselect',
            responsive: 'scroll',
            download: false,
            selectableRows: false,
            rowsPerPage: 15,
            rowsPerPageOptions: [15, 50, 100]
          }
          let bestsellers = []
          if (this.props.bestsellers.data) {
            bestsellers = this.props.bestsellers.data.bestsellers.map(b => ([
              b.id,
              b.name,
              b
            ]))
          }
          return (
            <main>
              <Dialog
                open={this.state.currentBestseller !== null}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleModalClose}
              >
                <DialogTitle>
                  {t('CONFIRMATION_REQUIRED')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('DELETION_DESCRIPTION')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.handleModalClose}
                    color='primary'>
                    {t('CLOSE')}
                  </Button>
                  <Button
                    onClick={this.delete}
                    color='primary'>
                    {t('DELETE')}
                  </Button>
                </DialogActions>
              </Dialog>
              {
                (bestsellers.length === 0 && this.props.bestsellers.loading) &&
                <Loading />
              }
              {
                (!this.props.bestsellers.loading || bestsellers.length > 0) &&
                <MUIDataTable data={bestsellers} columns={columns} options={options} />
              }
            </main>
          )
        }}
      </NamespacesConsumer >
    )
  }
}

export default connect(state => ({
  bestsellers: state.revolutilityBestsellers
}))(withI18n()(Bestsellers))
