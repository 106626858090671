import queryString from 'query-string'

export default ({ url, width, height }) => () => new Promise((resolve, reject) => {
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5
  const loginWindow = window.open(
    url,
    '',
    `location=0,status=0,width=${width},height=${height},left=${left},top=${top}`
  )
  const pollForToken = setInterval(() => {
    try {
      if (loginWindow.closed) {
        clearInterval(pollForToken)
        reject(new Error('Login was not completed'))
      }
      const params = queryString.parse(loginWindow.location.hash)
      if (params.access_token) {
        clearInterval(pollForToken)
        resolve({
          accessToken: params.access_token,
          idToken: params.id_token
        })
        loginWindow.close()
      }
    } catch (e) {
    }
  }, 100)
})
