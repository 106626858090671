export const FETCH = '@revolucharge/orders/fetch'
export const FETCH_DONE = '@revolucharge/orders/fetch_done'
export const FETCH_DETAILS = '@revolucharge/orders/fetch_details'
export const FETCH_DETAILS_DONE = '@revolucharge/orders/fetch_details_done'
export const REFUND = '@revolucharge/orders/refund'
export const REFUND_DONE = '@revolucharge/orders/refund_done'
export const NOTIFY = '@revolucharge/orders/notify'
export const NOTIFY_DONE = '@revolucharge/orders/notify_done'

export default {
  fetch: ({ limit = 10, offset = 0, searchterm = null } = {}) => ({
    type: FETCH,
    payload: {
      limit, offset, searchterm
    }
  }),
  putOrders: ({ orders, count, page, limit }) => ({
    type: FETCH_DONE,
    payload: { orders, count, page, limit }
  }),
  fetchDetails: ({ id }) => ({
    type: FETCH_DETAILS,
    payload: { id }
  }),
  putDetails: ({ details }) => ({
    type: FETCH_DETAILS_DONE,
    payload: { details }
  }),
  refund: ({ id }) => ({
    type: REFUND,
    payload: { id }
  }),
  refundDone: () => ({
    type: REFUND_DONE,
    payload: {}
  }),
  notify: ({ id, email }) => ({
    type: NOTIFY,
    payload: { id, email }
  }),
  notifyDone: () => ({
    type: NOTIFY_DONE,
    payload: {}
  })
}
