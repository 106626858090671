import {
  FETCH,
  FETCH_DONE,
  FETCH_DETAILS,
  FETCH_DETAILS_DONE,
  REFUND,
  REFUND_DONE,
  NOTIFY,
  NOTIFY_DONE
} from 'actions/revolutility/orders'

export default (
  state = {
    data: {},
    refunding: false,
    notifying: false,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign(
        {},
        state,
        { loading: true, data: { ...state.data, limit: action.payload.limit } }
      )
    }
    case FETCH_DONE: {
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            orders: action.payload.orders,
            count: action.payload.count,
            page: action.payload.page,
            limit: action.payload.limit
          }
        })
    }
    case FETCH_DETAILS: {
      return Object.assign({}, state, { loading: true })
    }
    case FETCH_DETAILS_DONE: {
      return Object.assign(
        {},
        state,
        { loading: false, data: { ...state.data, details: action.payload.details } }
      )
    }
    case REFUND: {
      return Object.assign(
        {},
        state,
        { refunding: true }
      )
    }
    case REFUND_DONE: {
      return Object.assign(
        {},
        state,
        { refunding: false }
      )
    }
    case NOTIFY: {
      return Object.assign(
        {},
        state,
        { notifying: true }
      )
    }
    case NOTIFY_DONE: {
      return Object.assign(
        {},
        state,
        { notifying: false }
      )
    }
    default: return state
  }
}
