export const FETCH = '@revolucharge/config/fetch'
export const FETCH_DONE = '@revolucharge/config/fetch_done'
export const UPDATE = '@revolucharge/config/update'
export const UPDATE_DONE = '@revolucharge/config/update_done'

export default {
  fetch: () => ({
    type: FETCH,
    payload: {}
  }),
  putConfig: ({ config }) => ({
    type: FETCH_DONE,
    payload: { config }
  }),
  update: ({
    config
  }) => ({
    type: UPDATE,
    payload: {
      config
    }
  }),
  updateDone: () => ({
    type: UPDATE_DONE,
    payload: {}
  })
}
