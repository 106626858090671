export const FETCH = '@revolucharge/transfers/fetch'
export const FETCH_DONE = '@revolucharge/transfers/fetch_done'
export const APPROVE = '@revolucharge/transfers/approve'
export const APPROVE_DONE = '@revolucharge/transfers/approve_done'

export default {
  fetch: ({ limit = 10, offset = 0, searchterm = null } = {}) => ({
    type: FETCH,
    payload: {
      limit, offset, searchterm
    }
  }),
  putTransfers: ({ transfers, count, page, limit }) => ({
    type: FETCH_DONE,
    payload: { transfers, count, page, limit }
  }),
  approve: ({ transferId, orderId }) => ({
    type: APPROVE,
    payload: { transferId, orderId }
  }),
  approveDone: () => ({
    type: APPROVE_DONE,
    payload: {}
  })
}
