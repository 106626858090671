export const FETCH = '@revolucharge/cysend_operators/fetch'
export const FETCH_DONE = '@revolucharge/cysend_operators/fetch_done'
export const UPDATE = '@revolucharge/cysend_operators/update'
export const UPDATE_DONE = '@revolucharge/cysend_operators/update_done'

export default {
  fetch: ({ limit = 10, offset = 0, searchterm = null } = {}) => ({
    type: FETCH,
    payload: {
      limit, offset, searchterm
    }
  }),
  putOperators: ({ operators, count, page, limit }) => ({
    type: FETCH_DONE,
    payload: { operators, count, page, limit }
  }),
  update: ({
    cysendOperatorId,
    operatorId,
    productName,
    limit,
    offset,
    searchterm
  }) => ({
    type: UPDATE,
    payload: {
      cysendOperatorId,
      operatorId,
      productName,
      limit,
      offset,
      searchterm
    }
  }),
  updateDone: () => ({
    type: UPDATE_DONE,
    payload: {}
  })
}
