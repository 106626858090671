import querystring from 'querystring'
export default ({ fetch, tokens, revoluchargeUrl, revolutilityUrl }) => ({
  revolucharge: {
    getPromotions: () => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/promotions`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    highlightPromotion: ({ id, highlight }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/promotion/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ highlight })
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getOrders: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/orders?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getOrderDetails: ({ id }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/order/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    refundOrder: ({ orderId }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/order/${orderId}/refund`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    resendConfirmation: ({ orderId, email }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/order/${orderId}/notify`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getProviders: () => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/providers`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    toggleProvider: ({ id, disabled }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/provider/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ disabled })
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getOperators: ({ limit = 15, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/operators?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    updateOperator: ({ id, name, logo, careNumber, disabled = null }) => new Promise((resolve, reject) => {
      const request = {}
      if (name) {
        request.name = name
      }
      if (logo) {
        request.logo = logo
      }
      if (careNumber) {
        request.careNumber = careNumber
      }
      if (disabled !== null) {
        request.disabled = disabled
      }
      fetch(`${revoluchargeUrl}/admin/operator/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getTransfers: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/transfers?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    approveTransfer: ({ transferId }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/transfer/${transferId}/approve`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getProducts: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/products?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getProviderOperators: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/providers/operators?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getCysendOperators: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revoluchargeUrl}/admin/providers/cysend/operators?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    updateCysendOperator: ({ cysendOperatorId, operatorId, productName }) => new Promise((resolve, reject) => {
      const request = { operatorId, productName }
      fetch(`${revoluchargeUrl}/admin/provider/cysend/operator/${cysendOperatorId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getConfig: () => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/config`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    updateConfig: ({ config }) => new Promise((resolve, reject) => {
      fetch(`${revoluchargeUrl}/admin/config`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getBi: async () => {
      const response = await fetch(`${revoluchargeUrl}/admin/bi`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    listBatchOrders: async () => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    addBatchOrder: async ({ productId, name }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ productId, name })
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    getBatchOrder: async ({ batchOrderId }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders/${batchOrderId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    getBatchOrderResultsDownloadUrl: async ({ batchOrderId }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders/download/${batchOrderId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    markBatchOrderAsFinished: async ({ batchOrderId }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders/finish/${batchOrderId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    enqueueBatchOrders: async ({ batchOrderId }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/batch_orders/enqueue/${batchOrderId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    getReports: async ({ start, end }) => {
      const response = await fetch(`${revoluchargeUrl}/admin/bi/reports`, {
        method: 'POST',
        body: JSON.stringify({ start, end }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    }
  },
  revolutility: {

    getOrders: ({ limit = 100, offset = 0, searchterm = null }) => new Promise((resolve, reject) => {
      const request = { limit, offset }
      if (searchterm) {
        request.searchterm = searchterm
      }
      fetch(`${revolutilityUrl}/admin/orders?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),

    getOrderDetails: ({ id }) => new Promise((resolve, reject) => {
      fetch(`${revolutilityUrl}/admin/order/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          r.json().then(resolve).catch(reject)
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),

    getConfig: async () => {
      const response = await fetch(`${revolutilityUrl}/admin/config`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Got wrong response code')
      }
    },

    getProducts: async ({ limit = 100, offset = 0, name = null }) => {
      const request = { limit, offset }
      if (name) {
        request.name = name
      }
      const productsResponse = await fetch(`${revolutilityUrl}/admin/products?${querystring.stringify(request)}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (productsResponse.ok) {
        const configResponse = await fetch(`${revolutilityUrl}/admin/config`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokens.get().accessToken}`
          }
        })
        if (configResponse.ok) {
          const config = await configResponse.json()
          const productsJson = await productsResponse.json()
          const commissionResponse = await fetch(`${revolutilityUrl}/admin/products/commissions`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${tokens.get().accessToken}`
            }
          })
          if (commissionResponse.ok) {
            const commissionJson = (await commissionResponse.json()).productCommissions
            const mappedElements = productsJson.elements.map(p => {
              const filteredCommissions = commissionJson.filter(c => {
                return c.providerId === p.provider.id && c.reference === p.product.reference
              })
              if (filteredCommissions.length > 0) {
                p.commission = filteredCommissions[0]
              } else {
                p.commission = {
                  percentage: config.revenue
                }
              }
              return p
            })
            return {
              ...productsJson,
              elements: mappedElements
            }
          } else {
            throw new Error('Got wrong response code')
          }
        } else {
          throw new Error('Got wrong response code')
        }
      } else {
        throw new Error('Got wrong response code')
      }
    },

    setCommission: async ({ reference, providerId, fixedAmount, percentage }) => {
      const response = await fetch(`${revolutilityUrl}/admin/products/commissions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ reference, providerId, fixedAmount, percentage })
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    sync: async () => {
      const response = await fetch(`${revolutilityUrl}/admin/providers/sync`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    updateConfig: ({ config }) => new Promise((resolve, reject) => {
      fetch(`${revolutilityUrl}/admin/config`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(config)
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getBestsellers: async () => {
      const response = await fetch(`${revolutilityUrl}/admin/products/bestsellers`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    addBestseller: async ({ bestseller }) => {
      const response = await fetch(`${revolutilityUrl}/admin/products/bestsellers`, {
        method: 'POST',
        body: JSON.stringify(bestseller),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    deleteBestseller: async ({ bestsellerId }) => {
      const response = await fetch(`${revolutilityUrl}/admin/products/bestseller/${bestsellerId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
    },
    refundOrder: ({ orderId }) => new Promise((resolve, reject) => {
      fetch(`${revolutilityUrl}/admin/order/${orderId}/refund`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    resendConfirmation: ({ orderId, email }) => new Promise((resolve, reject) => {
      fetch(`${revolutilityUrl}/admin/order/${orderId}/notify`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      }).then(r => {
        if (r.ok) {
          resolve()
        } else {
          reject(new Error('Wrong response code'))
        }
      }).catch(reject)
    }),
    getBi: async () => {
      const response = await fetch(`${revolutilityUrl}/admin/bi`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    },
    getReports: async ({ start, end }) => {
      const response = await fetch(`${revolutilityUrl}/admin/bi/reports`, {
        method: 'POST',
        body: JSON.stringify({ start, end }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.get().accessToken}`
        }
      })
      if (!response.ok) {
        throw new Error('Got wrong response code.')
      }
      return response.json()
    }
  }
})
