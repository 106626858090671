import {
  FETCH,
  FETCH_DONE,
  UPDATE,
  UPDATE_DONE
} from 'actions/revolucharge/config'

export default (
  state = {
    data: null,
    updating: false,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case FETCH: {
      return Object.assign(
        {},
        state,
        { loading: true }
      )
    }
    case FETCH_DONE: {
      return Object.assign(
        {},
        state,
        {
          loading: false,
          data: {
            ...action.payload.config
          }
        })
    }
    case UPDATE: {
      return Object.assign(
        {},
        state,
        { updating: true }
      )
    }
    case UPDATE_DONE: {
      return Object.assign(
        {},
        state,
        { updating: false }
      )
    }
    default: return state
  }
}
