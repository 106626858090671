import React from 'react'
import { Route } from 'react-router-dom'
import ErrorBoundary from '../../ErrorBoundary'

const PublicRoute = ({ component: Component, rest }) => (
  <Route {...rest} render={({ props }) => {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )
  }} />
)
export default PublicRoute
