import React from 'react'
import { hot } from 'react-hot-loader'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import PublicRoute from 'components/main/layouts/public/Route'
import PrivateRoute from 'components/main/layouts/private/Route'
import { NamespacesConsumer } from 'react-i18next'

import Login from 'components/login/Login'
import Orders from 'components/revolucharge/orders/Orders'
import OrderDetail from 'components/revolucharge/orders/OrderDetail'
import Promotions from 'components/revolucharge/promotions/Promotions'
import Providers from 'components/revolucharge/providers/Providers'
import Operators from 'components/revolucharge/operators/Operators'
import Transfers from 'components/revolucharge/transfers/Transfers'
import BatchOrders from 'components/revolucharge/batchOrders/BatchOrders'
import BatchOrder from 'components/revolucharge/batchOrders/BatchOrder'
import Products from 'components/revolucharge/products/Products'
import ProviderOperators from 'components/revolucharge/providerOperators/ProviderOperators'
import CysendOperators from 'components/revolucharge/cysendOperators/CysendOperators'
import Config from 'components/revolucharge/config/Config'
import RevoluchargeOrderReports from 'components/revolucharge/orderReports/OrderReports'

import RevolutilityOrders from 'components/revolutility/orders/Orders'
import RevolutililityOrderDetail from 'components/revolutility/orders/OrderDetail'
import RevolutilityProducts from 'components/revolutility/products/Products'
import RevolutilityBestsellers from 'components/revolutility/bestsellers/Bestsellers'
import RevolutilityConfig from 'components/revolutility/config/Config'
import RevolutilityOrderReports from 'components/revolutility/orderReports/OrderReports'

import Dashboard from 'components/dashboard/Dashboard'

import authActions from 'actions/auth'

class Router extends React.Component {
  constructor ({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentDidMount () {
    this.dispatch(authActions.check())
  }

  render () {
    return (
      <NamespacesConsumer ns={['layout']}>
        {t => (
          <BrowserRouter>
            <Switch>
              <PrivateRoute exact path='/revolucharge/orders' name={`${t('ORDERS')} REVOLUCHARGE`} component={Orders} />
              <PrivateRoute exact path='/revolucharge/transfers' name={`${t('TRANSFERS')} REVOLUCHARGE`} component={Transfers} />
              <PrivateRoute exact path='/revolucharge/batchorders' name={`${t('BATCH ORDERS')} REVOLUCHARGE`} component={BatchOrders} />
              <PrivateRoute exact path='/revolucharge/batchorder/:id' name={`${t('BATCH ORDER')} REVOLUCHARGE`} component={BatchOrder} />
              <PrivateRoute exact path='/revolucharge/products' name={`${t('PRODUCTS')} REVOLUCHARGE`} component={Products} />
              <PrivateRoute exact path='/revolucharge/order/:id' name={`${t('ORDER_DETAIL')} REVOLUCHARGE`} component={OrderDetail} />
              <PrivateRoute exact path='/revolucharge/promotions' name={`${t('PROMOTIONS')} REVOLUCHARGE`} component={Promotions} />
              <PrivateRoute exact path='/revolucharge/providers' name={`${t('PROVIDERS')} REVOLUCHARGE`} component={Providers} />
              <PrivateRoute exact path='/revolucharge/operators' name={`${t('OPERATORS')} REVOLUCHARGE`} component={Operators} />
              <PrivateRoute exact path='/revolucharge/cysend' name={`${t('CYSEND_MAPPING')} REVOLUCHARGE`} component={CysendOperators} />
              <PrivateRoute exact path='/revolucharge/broker' name={`${t('BROKER')} REVOLUCHARGE`} component={ProviderOperators} />
              <PrivateRoute exact path='/revolucharge/config' name={`${t('CONFIG')} REVOLUCHARGE`} component={Config} />
              <PrivateRoute exact path='/revolucharge/reports' name={`${t('REPORTS')} REVOLUCHARGE`} component={RevoluchargeOrderReports} />
              <PrivateRoute exact path='/revolutility/orders' name={`${t('ORDERS')} REVOLUTILITY`} component={RevolutilityOrders} />
              <PrivateRoute exact path='/revolutility/order/:id' name={`${t('ORDER_DETAIL')} REVOLUTILITY`} component={RevolutililityOrderDetail} />
              <PrivateRoute exact path='/revolutility/products' name={`${t('PRODUCTS')} REVOLUTILITY`} component={RevolutilityProducts} />
              <PrivateRoute exact path='/revolutility/bestsellers' name={`${t('BESTSELLERS')} REVOLUTILITY`} component={RevolutilityBestsellers} />
              <PrivateRoute exact path='/revolutility/config' name={`${t('CONFIG')} REVOLUTILITY`} component={RevolutilityConfig} />
              <PrivateRoute exact path='/revolutility/reports' name={`${t('REPORTS')} REVOLUTILITY`} component={RevolutilityOrderReports} />
              <PrivateRoute exact path='/dashboard' component={Dashboard} />
              <PublicRoute exact path='/login' component={Login} />
              <Redirect to='/dashboard' />
            </Switch>
          </BrowserRouter>

        )}
      </NamespacesConsumer>
    )
  }
}
export default hot(module)(connect((state) => ({ auth: state.auth }))(Router))
