import { call, put, takeLatest } from 'redux-saga/effects'
import productsActions, { FETCH, SET_COMMISSION, SYNC } from 'actions/revolutility/products'
import notificationsActions from 'actions/notifications'

export default ({ api, i18n }) => {
  function * getProducts ({ payload }) {
    try {
      const products = yield call(api.revolutility.getProducts, payload)
      yield put(productsActions.putProducts({
        count: products.count,
        products: products.elements,
        page: payload.offset / payload.limit,
        limit: payload.limit,
        name: payload.name
      }))
    } catch (e) {
      console.log(e)
      yield put(productsActions.putProducts({ products: [] }))
    }
  }

  function * setCommission ({ payload }) {
    try {
      yield call(api.revolutility.setCommission, payload)
      yield put(productsActions.setCommissionDone())
      yield put(productsActions.fetch(payload))
    } catch (e) {
      console.log(e)
      yield put(productsActions.setCommissionDone())
    }
  }

  function * sync () {
    try {
      yield call(api.revolutility.sync)
      yield put(notificationsActions.enqueue({
        message: i18n.t('SYNC_SUCCESS'),
        options: {
          variant: 'success'
        }
      }))
    } catch (e) {
      console.log(e)
    }
  }

  return function * watch () {
    yield takeLatest(FETCH, getProducts)
    yield takeLatest(SET_COMMISSION, setCommission)
    yield takeLatest(SYNC, sync)
  }
}
