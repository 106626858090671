import React from 'react'
import { NamespacesConsumer } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import OperatorSelect from './OperatorSelect'
import { FormControl } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { isEqual } from 'lodash-es'

const styles = theme => ({
  loadingButtonWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  text: {
    marginBottom: '10px'
  },
  formControl: {
    marginBottom: theme.spacing.unit * 2
  }
})

const Transition = (props) => {
  return (<Slide direction='up' {...props} />)
}

class UpdateModal extends React.Component {
  state = {
    selectedOperator: null,
    productName: ''
  }

  update = () => {
    let productName = null
    if (this.state.productName && this.state.productName.length > 0) {
      productName = this.state.productName
    }
    this.props.onUpdate({
      cysendOperatorId: this.props.operator.id,
      operatorId: this.state.selectedOperator ? this.state.selectedOperator.value : null,
      productName: productName
    })
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.operator && !isEqual(prevProps.operator, this.props.operator)
    ) {
      let operator = null
      let productName = ''
      if (this.props.operator.operator) {
        operator = {
          label: this.props.operator.operator.name,
          value: this.props.operator.operator.id
        }
      }
      if (this.props.operator.productName) {
        productName = this.props.operator.productName
      }
      this.setState({
        selectedOperator: operator,
        productName: productName
      })
    }
  }

  close = () => {
    this.props.onClose()
  }

  render () {
    const classes = this.props.classes
    return (
      <NamespacesConsumer ns={['cysendOperators']}>
        {t => (
          <Dialog
            open={this.props.operator !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.close}
          >
            <DialogTitle>
              {t('UPDATE_OPERATOR_MODAL_TITLE')}
            </DialogTitle>
            <DialogContent>
              {this.props.operator &&
                <div>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      value={this.props.operator.id}
                      label={t('ID')}
                      disabled
                      type='text'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      value={this.props.operator.country.name}
                      label={t('COUNTRY')}
                      disabled
                      type='text'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      value={this.props.operator.name}
                      label={t('NAME')}
                      disabled
                      type='text'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      disabled
                      value={this.props.operator.type.name}
                      label={t('TYPE')}
                      type='text'
                    />
                  </FormControl>
                  <OperatorSelect
                    error={false}
                    value={this.state.selectedOperator}
                    initialSearch={this.state.selectedOperator ? this.state.selectedOperator.name : null}
                    onValue={value => { this.setState({ selectedOperator: value }) }}
                  />
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      error={false}
                      helperText={t('PRODUCT_NAME_HELPERTEXT')}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.productName}
                      onChange={event => { this.setState({ productName: event.target.value }) }}
                      label={t('PRODUCT_NAME')}
                      type='text'
                    />
                  </FormControl>
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color='default'> {t('CLOSE')} </Button>
              <div className={classes.loadingButtonWrapper}>
                <Button onClick={this.update} color='primary'> {t('UPDATE')} </Button>
                {this.props.loading &&
                  <CircularProgress size={24} className={classes.buttonProgress} />
                }
              </div>
            </DialogActions>
          </Dialog>
        )}
      </NamespacesConsumer>
    )
  }
}

export default withStyles(styles)(UpdateModal)
